const KeyboardEventKeyCode = {
  Space: 32,
  Escape: 27,
  Enter: 13,
  Delete: 46,
  BackSpace: 8,
  Tab: 9,
  LeftArrow: 37,
  RightArrow: 38,
  UpArrow: 39,
  DownArrow: 40
};

export default KeyboardEventKeyCode;

