import i18n from 'micro-i18n';
import { enBaseLang } from 'locales';
import { arrayIncludes, handleError, isNullOrUndef, resolveCdn } from 'utils';
import { ErrorState, Locales, LocaleState } from 'consts';

/**
 * Get CDN locale stringset file url, defaulting to EN if not
 * found in `Locales`.
 *
 * @param {string} locale locale identifier string
 * @returns CDN url to locale stringset json file
 */
export const getLocaleUrl = (locale) => {
  const localeValues = Object.values(Locales);
  const localeFilePrefix = arrayIncludes(localeValues, locale) ? locale : Locales.EN;
  return resolveCdn(`locales/${localeFilePrefix}.json`);
};

export const getLocale = ({ store, locale, stringOverrides }) => {
  store.setState({ localeState: LocaleState.LocaleLoading });

  const loadDefaultLocale = () => {
    i18n.setLang(enBaseLang); // Set a baseLang if no locale is passed to getLocale
  };

  if (isNullOrUndef(locale)) {
    loadDefaultLocale();
    return { localeState: LocaleState.LocaleNotLoaded };
  } else {
    return fetch(getLocaleUrl(locale))
      .then(res => res.json())
      .then(langData => {
        const localeOverrides = stringOverrides && stringOverrides[locale];
        //NOTE: Known bug / race condition with multiple widgets on screen:
        // If there are multiple Widgets with different localeOverrides, the last one to i18n.setLang() wins out.
        // We decided to punt on fixing this because there may not be many sites with multiple widgets
        // Fixing this would require fork of micro-i18n to scope language to a particular ref.
        const updatedLangData = localeOverrides ? Object.assign({}, langData, localeOverrides) : langData;

        i18n.setLang(updatedLangData);
        return { localeState: LocaleState.LocaleLoaded };
      })
      .catch((e) => {
        loadDefaultLocale();
        return handleError(ErrorState.Locale, e);
      });
  }
};
