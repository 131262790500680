import { getTopmostPageDetail } from 'utils';

/**
 *
 * @param {string} [urlOverride] for widgets wrapped in iFrames we need the
 * flexibility to override with a supplied articleUrl
 * @returns {*}
 */
const getTopmostPageUrl = (urlOverride) => urlOverride || getTopmostPageDetail().url;

export default getTopmostPageUrl;
