import fetchJsonp from 'fetch-jsonp';
import { envConfig } from 'config';
import { DispatchEvents, ErrorState, JotType, SubmitState, WidgetState } from 'consts';
import {
  clearLocalBackupAlias,
  getLocalBackupAlias,
  dispatchEvent,
  handleError,
  idsync,
  isNullOrUndef,
  jsonToUri,
  widgetCompliance
} from 'utils';
import { jot } from 'actions/jot';

export const getComplianceUrl = (state, complianceVersion = 0, complianceOk = false, backupAlias) => {
  const { protocol, widgetComplianceEndpoint } = envConfig;
  const { hostname, questionsData, bootstrap } = state;
  const { id: targetId } = bootstrap;
  const complianceApiUrl = `${protocol}${hostname}${widgetComplianceEndpoint}`;
  const complianceParams = {
    target: targetId,
    accepted: complianceOk ? 1 : 0,
    alias: questionsData ? questionsData.alias : null,
    session: questionsData ? questionsData.session : null,
    backupAlias: isNullOrUndef(backupAlias) ? '' : backupAlias,
    version: complianceVersion
  };
  const complianceParamsString = jsonToUri(complianceParams);

  return `${complianceApiUrl}?${complianceParamsString}`;
};

export const setComplianceState = ({
                                     state,
                                     complianceState,
                                     complianceVersion,
                                     saveComplianceAnswer = false
                                   }) => {
  const { apiTimeoutMS } = envConfig;
  const { config, instanceId, hostname, backupAlias } = state;
  const { compliance } = config;
  const complianceOk = widgetCompliance.complianceStateOk(complianceState);
  //NOTE: when a Respondent accepts/declines we update config.compliance
  const updatedCompliance = Object.assign({}, compliance, { accepted: complianceOk });
  const updatedConfig = Object.assign({}, config, { compliance: updatedCompliance });
  const toState = {
    complianceState,
    config: updatedConfig
  };

  const acceptCompliance = () => {
    widgetCompliance.setComplianceStorage(complianceVersion, true);
  };

  const declineCompliance = () => {
    //on decline, clear our local backup alias, and then create a new one
    clearLocalBackupAlias();
    const newBackupAlias = getLocalBackupAlias();
    widgetCompliance.setComplianceStorage(complianceVersion, false);

    //NOTE: when a Respondent declines we revert / initialize a lot of state
    toState.submitState = SubmitState.NotReady;
    //set to BootstrapLoaded so we reload the target, which will recreate the cookie
    toState.widgetState = WidgetState.BootstrapLoaded;
    toState.questionsData = {};
    toState.finishData = {};
    toState.finishParams = [];
    toState.excludeParams = [];
    toState.submitIds = [];
    toState.servedIds = [];
    toState.backupAlias = newBackupAlias;
    toState.questionIndex = 0;
    toState.resetQuestions = true;
  };
  complianceOk ? acceptCompliance() : declineCompliance();

  jot({
    state,
    space: 'poll',
    type: JotType.Compliance,
    data: {
      accepted: complianceOk
    }
  });

  return saveComplianceAnswer
    ? fetchJsonp(getComplianceUrl(state, complianceVersion, complianceOk, backupAlias),
      { timeout: apiTimeoutMS })
      .then(res => res.json())
      .then(() => {
        if (complianceOk) {
          dispatchEvent(instanceId, this, DispatchEvents.IDSync, false, {
            hostname
          });
          idsync(updatedConfig, hostname);
        }
        return toState;
      })
      .catch((e) => {
        declineCompliance();
        return handleError(ErrorState.Compliance, e);
      })
    : toState;
};
