const removeUrlParam = (key, url) => {
  let cleanUrl = url.split('?')[0],
    param,
    paramsArray = [],
    queryString = (url.indexOf('?') !== -1) ? url.split('?')[1] : '';
  if (queryString !== '') {
    paramsArray = queryString.split('&');
    for (let i = paramsArray.length - 1; i >= 0; i -= 1) {
      param = paramsArray[i].split('=')[0];
      param === key && paramsArray.splice(i, 1);
    }
    cleanUrl = cleanUrl + (paramsArray.length > 0 ? '?'+ paramsArray.join('&') : '') ;
  }
  return cleanUrl;
}

export default removeUrlParam;
