import { ErrorState, LocaleState, WidgetState } from 'consts';
import { envConfig } from 'config';

// @param {toErrorState} string - value form ErrorState const
//    Example: ErrorState.Target
// @param {error} string|object: Error text or object
const handleError = (toErrorState, error) => {
  const { isTest, isDebugMode } = envConfig;
  const stateDefaults = toErrorState === ErrorState.None
    ? { errorState: toErrorState }
    : { widgetState: WidgetState.Error, errorState: toErrorState };
  let stateUpdates;

  //Switch on special cases where states could be different than defaults above
  switch (toErrorState) {
    case ErrorState.Bootstrap:
      stateUpdates = Object.assign({}, stateDefaults, { widgetState: WidgetState.BootstrapFailed });
      break;
    case ErrorState.Target:
      stateUpdates = Object.assign({}, stateDefaults, { widgetState: WidgetState.TargetFailed });
      break;
    case ErrorState.Locale:
      stateUpdates = Object.assign({}, stateDefaults, { localeState: LocaleState.LocaleFailedToLoad });
      break;
    default:
      stateUpdates = stateDefaults;
  }

  // Display error in console if not in test environment and isDebugMode and ErrorState other than None.
  if (error && toErrorState !== ErrorState.None && isDebugMode && !isTest) {
    //eslint-disable-next-line
    console.error('CSWidget handleError', { stateUpdates, error });
  }

  return stateUpdates;
};

export default handleError;
