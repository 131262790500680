import { arrayIncludes, isArray, isNullOrUndefOrEmpty, addStyle } from 'utils';
import { FileNamePrefix } from 'consts';
//FIXME: positionable.default should be renamed on the api because 'default' is a reserved javascript keyword.

const positionedStyleClass = 'csw-positionable';

// @param {number} heightMin - pixels for to apply as min-height.
//    Example: 400
// @param {HTMLElement} parentNode: DOM Element to compare with for min height.
const minHeightCanApply = (heightMin, parentNode) => {
  if (isNullOrUndefOrEmpty(heightMin) || isNullOrUndefOrEmpty(parentNode)) {
    return false;
  }
  const parentHeight = parentNode.getBoundingClientRect().height;
  return parentHeight && !(parentHeight < heightMin);
};

// @param {string} selector - sibling selectors to test against.
//    Example: 'span.storyimage,div.videoplayer,div.inline-slideshow,div.wcvideoplayer'
// @param {HTMLElement} domNode: DOM Element to target for insertion.
// @param {number} notNum: number of siblings before and after to check.
const isNextToSelector = (cssSelector, domNode, notNum = 3) => {
  if (isNullOrUndefOrEmpty(cssSelector) || isNullOrUndefOrEmpty(domNode)) {
    return false;
  }
  let n = domNode;
  let p = domNode;
  for (let sc = 0; sc < notNum; sc++) {
    n = n ? n.nextElementSibling : null;
    p = p ? p.previousElementSibling : null;
    if ((n && n.matches(cssSelector)) || (p && p.matches(cssSelector))) {
      return true;
    }
  }
  return false;
};

// @param {string} position - position string split by pipe '|'
//    Example: 'before|P:nth-child(4)'
const getPosition = (position) => {
  const posSplit = position.split('|');
  return { place: posSplit[0], selector: posSplit[1] };
};

// @param {string} cssSelector - css selector string
const getTargetNode = (cssSelector) => {
  return cssSelector ? document.querySelector(cssSelector) : null;
};

// @param {string} place - positioning insertion point. Examples: 'before'|'after'|'.25'
// @param {string} selector - css selector string
const getTargetNodeWithPlace = (place, cssSelector) => {
  if (isNullOrUndefOrEmpty(place) || isNullOrUndefOrEmpty(cssSelector)) {
    return null;
  }

  const elCount = document.querySelectorAll(cssSelector).length;
  const insertNth = Math.floor(elCount * place);
  const calcSelector = cssSelector + ':nth-of-type(' + insertNth + ')';
  return document.querySelector(calcSelector);
};

// @param {HTMLElement} positionableNode: Node object to be positioned
// @param {string} position - position string split by pipe '|'
//    Example: 'before|P:nth-child(4)'
// @param {number} heightMin - pixels for to apply as min-height.
//    Example: 400
// @param {string} notSelector - sibling selectors to avoid insertion.
//    Example: 'span.storyimage,div.videoplayer,div.inline-slideshow,div.wcvideoplayer'
// @param {number} notNum - number of siblings to check against the notSelector
// @param {string} css - css applied to the positionable element when positioned.
//    Example: ".csw-positionable { width: 400px; float:right; padding: 20px; }"
// @param {string} styleTagId - id applied to the style tag wrapping `css`.
const positionNode = (positionableNode, position, heightMin, notSelector, notNum, css, styleTagId) => {
  const { place, selector } = getPosition(position);
  const toTargetNode = getTargetNode(selector);

  if (positionableNode && toTargetNode && toTargetNode.parentNode) {
    const isPlaceBefore = place === 'before';
    const isPlaceAfter = place === 'after';
    const isPlaceOther = !isPlaceBefore && !isPlaceAfter && !isNullOrUndefOrEmpty(place);
    let targetNode;

    if (isPlaceBefore) {
      targetNode = toTargetNode;
    } else if (isPlaceAfter) {
      targetNode = toTargetNode.nextSibling;
    } else if (isPlaceOther) {
      targetNode = getTargetNodeWithPlace(place, selector);
    }

    if (targetNode && (isNullOrUndefOrEmpty(notSelector) || !isNextToSelector(notSelector, targetNode, notNum))) {
      if (minHeightCanApply(heightMin, toTargetNode.parentNode)) {
        positionableNode.style.minHeight = `${heightMin}px`;
      }

      positionableNode.classList.add(positionedStyleClass);
      if (styleTagId && !isNullOrUndefOrEmpty(css)) {
        addStyle(css, `${FileNamePrefix.PositionableStyle}${styleTagId}`,
          document.getElementsByTagName('head')[0]);
      }
      toTargetNode.parentNode.insertBefore(positionableNode, targetNode);
      return true;
    }
  }

  return false;
};

// @param {HTMLElement} positionableNode: Node object to be positioned
// @param {object} positionable: from target config, contains the following properties
//    @param {boolean} on - to know whether the feature is enabled at all
//    @param {string} p - PINNED position info for that particular url.
//      Example: "before|P:nth-child(4)"
//    @param {string[]} default - list of position strings with place
//      and "selector" to try in order for placement. NOTE: @default will be null a @p value is present.
//      Example: [E"before|P:nth-child(4)"]
//    @param {number} h - is a height minimum.
//      Example: 400
//    @param {string} not - a single css selector string to compare siblings against to
//      NOT place the widget next to those items in the DOM.
//      Example: 'span.storyimage,div.videoplayer,div.inline-slideshow,div.wcvideoplayer'
//    @param {number} notNum - number of elements to check against the not selector before
//      and after the positioned widget.
//    @param {string} css - css applied to the positionable element when positioned.
//      Example: ".csw-positionable { width: 400px; float:right; padding: 20px; }"
// @param {string} styleTagId: uniqueId for the style tag to be inserted for
// @param {boolean} isEditable: boolean to prevent hiding the widget if editing user
const positionContainer = (positionableNode, positionable, styleTagId, isEditable) => {
  const isPositionable = positionable && positionable.on && positionableNode
    && !arrayIncludes(positionableNode.classList, positionedStyleClass);

  if (isPositionable) {
    const pinPosition = positionable['p'];
    const defPositions = positionable['default'];
    const minHeight = positionable['h'];
    const notSelector = positionable['not'];
    //FIXME: notNum is not in the api yet.
    const notNum = positionable['notNum'];
    const css = positionable['css'];
    const hasDefaults = (isArray(defPositions) && defPositions.length > 0);
    let isPositioned = (typeof pinPosition === 'string') // Test pinned first
      && positionNode(positionableNode, pinPosition, minHeight, notSelector, notNum, css, styleTagId);

    // NOTE: Only test defaultPosition if pinnedElement was not positioned
    if (!isPositioned && hasDefaults) {
      for (let dpi = 0; dpi < defPositions.length; dpi++) {
        isPositioned = positionNode(positionableNode, defPositions[dpi], minHeight, notSelector,
          notNum, css, styleTagId);

        if (isPositioned) {
          //Note: break on first found element for defPositions that is not next to a notSelector
          break;
        }
      }
    }

    //NOTE: For respondent users only, hide pinnedElement if it could not be successfully positioned.
    if ((!isNullOrUndefOrEmpty(pinPosition) || hasDefaults) && !isPositioned) {
      positionableNode.classList.add('csw-positionable-failed');
      !isEditable && positionableNode.setAttribute('style', 'display:none');
    }
  }
};

export default positionContainer;

