import { getBootstrap } from 'actions/getBootstrap';
import { getTarget } from 'actions/getTarget';
import { getLocale } from 'actions/getLocale';
import { getQuestions } from 'actions/getQuestions';
import { getResults } from 'actions/getResults';
import { getFinish } from 'actions/getFinish';
import { jot } from 'actions/jot';
import { onAfterShowingResults } from 'actions/onAfterShowingResults';
import { onHPTriggered } from 'actions/onHPTriggered';
import { onNextResult } from 'actions/onNextResult';
import { onOptionChanged } from 'actions/onOptionChanged';
import { onPreviousQuestion } from 'actions/onPreviousQuestion';
import { onPreviousQuestionFromResult } from 'actions/onPreviousQuestionFromResult';
import { onPreviousResult } from 'actions/onPreviousResult';
import { onTextChanged } from 'actions/onTextChanged';
import { setComplianceState } from 'actions/setComplianceState';
import { setDarkMode } from 'actions/setDarkMode';
import { setReadOnly } from 'actions/setReadOnly';
import { setServedIds } from 'actions/setServedIds';
import { setShareableLink } from 'actions/setShareableLink';
import { setSubmitIds } from 'actions/setSubmitIds';
import { setSubmitState } from 'actions/setSubmitState';
import { setWidgetState } from 'actions/setWidgetState';
import { setWidgetInteractable } from 'actions/setWidgetInteractable';
import { setWidgetInScroll } from 'actions/setWidgetInScroll';

//TODO cleanup naming patterns
export const actions = (store) => ({
  getBootstrap: (state, args = []) => getBootstrap({ store, state, ...args }),
  getTarget: (state, args = []) => getTarget({ store, state, ...args }),
  getLocale: (state, args = []) => getLocale({ store, ...args }),
  getQuestions: (state, args = []) => getQuestions({ store, state, ...args }),
  getResults: (state, args = []) => getResults({ store, state, ...args }),
  getFinish: (state, args = []) => getFinish({ state, ...args }),
  onAfterShowingResults: (state, args) => onAfterShowingResults({ state, ...args }),
  onHPTriggered: (state, args) => onHPTriggered({ store, state, ...args }),
  onNextResult: (state, args) => onNextResult({ state, ...args }),
  onOptionChanged: (state, args) => onOptionChanged({ state, ...args }),
  onPreviousQuestion: (state, args) => onPreviousQuestion({ state, ...args }),
  onPreviousQuestionFromResult: (state, args) => onPreviousQuestionFromResult({ state, ...args }),
  onPreviousResult: (state, args) => onPreviousResult({ state, ...args }),
  onTextChanged: (state, args = []) => onTextChanged({ state, ...args }),
  setComplianceState: (state, args = []) => setComplianceState({ state, ...args }),
  setDarkMode: (state, ...args) => setDarkMode(state, ...args),
  setReadOnly: (state, ...args) => setReadOnly(state, ...args),
  setServedIds: (state, ...args) => setServedIds(state, ...args),
  setShareableLink: (state, ...args) => setShareableLink(state, ...args),
  setSubmitIds: (state, ...args) => setSubmitIds(state, ...args),
  setSubmitState: (state, ...args) => setSubmitState(state, ...args),
  setWidgetState: (state, ...args) => setWidgetState(state, ...args),
  setWidgetInteractable: (state, ...args) => setWidgetInteractable(state, ...args),
  setWidgetInScroll: (state, ...args) => setWidgetInScroll(state, ...args),
  jot: (state, args = []) => jot({ state, ...args }),
});
