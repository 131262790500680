import { QuestionType, SubmitState } from 'consts';
import { getNumberOptionsSelected, getNumberRowsCompleted, isNullOrUndefOrEmpty } from 'utils';

/**
 * Toggle an option on/off for the currently active question, and update
 * SubmitState in store.
 *
 * @param {Object} state - current state
 * @param {Object} option - option to toggle
 * @param {*=} row - row of option to toggle (optional, only for spectrum/matrix)
 * @param {boolean} autoAdvance - auto advance for radio selection only.
 */
export const onOptionChanged = ({ state, option = {}, row = null, autoAdvance = false }) => {
  const { config, questionsData = {}, questionIndex } = state;
  const { id: optionId, isOptOut: optionIsOptOut, selected: optionSelected } = option;
  const optingOut = optionIsOptOut && !optionSelected;
  const { ba: badActor } = config;
  let submitState = SubmitState.NotReady; //default to not ready
  const questions = questionsData && questionsData['questions'];
  const updateQuestionsData = Object.assign({}, questionsData);
  const currQuestionId = questionsData.questions[questionIndex].id;
  const hasAutoAdvance = autoAdvance && badActor !== true;//NOTE: badActors must manually submit

  updateQuestionsData.questions = questions && questions.map((question) => {
    let { options, checkbox, rows, type, id } = question;
    const isCurrQuestion = currQuestionId === id;

    if (isCurrQuestion) {
      if (options) {
        let numSelected = getNumberOptionsSelected(options);

        //checkbox and radio questions
        options = options.map((currOption) => {
          const { id: currOptionId, isOptOut: currIsOptOut, selected: currSelected } = currOption;
          // const updatedCurrOption = Object.assign({}, currOption);
          if (type === QuestionType.Checkbox) {
            if (optionId === currOptionId) {
              //observe max limit. additionally, if opt out, allow selection no matter what
              currOption.selected = (currSelected || optingOut || numSelected < checkbox['maxChecked'])
                ? !currSelected : false;
            } else if ((optingOut && currSelected) || (!optingOut && currIsOptOut)) {
              currOption.selected = false;
            }
          } else {
            currOption.selected = (option === currOption);
          }
          return currOption;
        });

        numSelected = getNumberOptionsSelected(options);

        //Update submitState
        if (type === QuestionType.Radio
          && !isNullOrUndefOrEmpty(optionId)) {
          submitState = hasAutoAdvance ? SubmitState.Auto : SubmitState.Ready;
        } else if (type === QuestionType.Checkbox
          && (optingOut ||
             (numSelected !== 0
              && numSelected >= checkbox['minChecked']
              && numSelected <= checkbox['maxChecked'])
             )) {
          //Note in above expression: if we are opting out, we ignore min/max checked
          //so that we can let them submit opt out no matter what
          submitState = SubmitState.Ready;
        }

      } else if (rows) {
        //spectrum and matrix questions
        rows = rows.map((currRow) => {
          if (row === currRow) {
            currRow.options = currRow.options.map((currOption) => {
              currOption.selected = (option === currOption);
              return currOption;
            })
          }
          return currRow;
        });

        const numRowsCompleted = getNumberRowsCompleted(rows);

        //Update submitState
        submitState = numRowsCompleted === rows.length ? SubmitState.Ready : SubmitState.NotReady;
      }
    }

    return question;
  });

  return { questionsData: Object.assign({}, updateQuestionsData), submitState };
};
