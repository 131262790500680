/**
 * BCP 47 Language Codes in support of both backend standard
 * and browser navigator.language standard
 * https://developer.mozilla.org/en-US/docs/Web/API/Navigator/language
 * https://tools.ietf.org/rfc/bcp/bcp47.txt
 *
 * These map to files in locales/ which should follow the same naming pattern ie
 * en.json, es.json, en-CA.json
 */
const Locales = {
  EN: 'en',
  ES: 'es',
  EN_CA: 'en-CA'
};

export default Locales;
