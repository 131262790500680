/**
 * Inserts tracking pixels into DOM.
 *
 * Do not call unless compliance is enabled and has been accepted.
 *
 * @param {*} pixels list of tracking pixel full urls
 */
const createTrackingPixels = (pixels = []) => {
  pixels.forEach(url => {
    document.createElement('img').src = url;
  });
};

export default createTrackingPixels;
