import { demographicsStorage, evalNatures, isNullOrUndefOrEmpty } from 'utils';

import { GAMTargeting } from 'consts';
import { useEffect } from 'react';

/** Google Ad Manager (GAM) Targeting
 *
 * Requires GAM js to be integrated in publisher HEAD.
 *
 * @param {*} config target configuration
 *
 * @return {{ hasTargeted: boolean }}
 */
const useGAMTargeting = (config = {}) => {
  const { natures = [], d: configDemographics } = config;
  const { gamTargetingEnabled = false } = evalNatures(natures);
  const demographics = demographicsStorage.getLocalStorageDemographics() || configDemographics;
  const getGoogleTag = () => window?.googletag || { cmd: [] };

  const insertTargeting = () => {
    //NOTE this can run more than once, intentionally, if demographics data changes
    const googletag = getGoogleTag();
    googletag.cmd.push(() => {
      // Configure page-level targeting for age (a) and gender (g)
      const demographicsArr = [];
      demographics['a'] && demographicsArr.push(demographics['a']);
      demographics['g'] && demographicsArr.push(demographics['g']);
      demographicsArr.length && googletag.pubads().setTargeting(GAMTargeting.DemographicsKey, demographicsArr);
    });
  };

  useEffect(() => {
    //fire call if targeting enabled and demographics value has changed in useEffect
    gamTargetingEnabled && !isNullOrUndefOrEmpty(demographics) && insertTargeting();
  }, [demographics, gamTargetingEnabled]);

  return {
    demographics
  };
};

export default useGAMTargeting;
