import {
  getAllUnderlyingQuestionIds,
  getQuestionIndexById,
  handleError,
  isNullOrUndef,
  widgetCompliance,
  demographicsStorage
} from 'utils';
import { envConfig } from 'config';
import { ErrorState } from 'consts';

export const getIsContainerSeen = (container, containerTop, containerHeight, scrollTop, innerHeight) => {
  if (isNullOrUndef(container)) {
    return false;
  }

  const ct = containerTop;
  const ch = containerHeight;
  const wt = scrollTop;
  const wh = innerHeight;
  const buffer = wh / 16;

  return (ct + ch < wh // container is initially entirely within view
    || ct + ch + buffer <= wt + wh // ...or container has been scrolled entirely into view
    || wt + wh - ct > wh / 4 // ...or the container occupies a significant portion of the viewport
    || ct - buffer <= wt  // ...or the container is too tall to fit in the viewport
    || wt + wh + buffer >= document.body.scrollHeight); // ...or scrolled to the bottom
};

export const getJotConfigSpace = (hostname) => {
  const { protocol } = envConfig;
  return {
    id: Math.floor(Math.random() * 4294967296) + '.' + Math.floor(Math.random() * 4294967296),
    URL: `${protocol}${hostname}/jot`,
    sequence: 0
  };
};

/**
 * Issue an web request for logging purposes
 *
 * @param {*} state
 * @param {*} space
 * @param {*} type
 * @param {*} data
 * @param {*} question
 */
export const jot = ({ state, space, type, data, question }) => {
  const { config, bootstrap, instanceId, urlContext, questionsData, hostname, readOnly, complianceState } = state;
  let { jotConfig } = state || {};

  if (readOnly) {
    return { jotConfig };
  }

  if (!jotConfig[space]) {
    //initialize jot config for named space
    jotConfig[space] = getJotConfigSpace(hostname);
  }

  const { natures, d: configDemographics, compliance, ba: badActor } = config;
  const demographics = demographicsStorage.getLocalStorageDemographics() || configDemographics;
  const { id } = bootstrap;
  const { innerHeight, innerWidth } = window;
  const { scrollTop, scrollLeft } = window.document.documentElement;
  const container = document.getElementById(instanceId);
  const { offsetTop, offsetLeft, offsetHeight } = container || {};
  const useRectTop = offsetTop < 100 && container.getBoundingClientRect;
  const containerTop = useRectTop ? container.getBoundingClientRect().top : offsetTop;

  const isContainerSeen = getIsContainerSeen(container, containerTop, offsetHeight, scrollTop, innerHeight);
  let defaultData = {
    target: id,
    natures: natures,
    instance: instanceId,
    isContainerSeen: isContainerSeen,
    context: urlContext,
    wx: scrollLeft,
    wy: scrollTop,
    wh: innerHeight,
    ww: innerWidth
  };

  if (container) {
    defaultData = Object.assign({}, defaultData, { cx: offsetLeft, cy: offsetTop });
  }

  //if data contains askable, add other question-related info
  if (!isNullOrUndef(question)) {
    const { id: questionId, usage } = question;

    defaultData = Object.assign({}, defaultData, {
      askable: questionId,
      usage,
      position: getQuestionIndexById(questionsData.questions, questionId),
      questions: getAllUnderlyingQuestionIds(question)
    });
  }

  //if we have compliance data, add it
  if (compliance) {
    defaultData = Object.assign({}, defaultData, {
      comp: widgetCompliance.complianceStateOk(complianceState)
    });
  }

  //if questionsData.session, add session/alias/rec info
  if (questionsData && questionsData.session) {
    defaultData = Object.assign({}, defaultData, {
      session: questionsData.session,
      alias: questionsData.alias,
      locale: questionsData.locale,
      rec: questionsData.rec,
    });
  }

  //if adEngage jot, attempt to look up canonical url
  if (space === 'ae') {
    const link = document.querySelector('link[rel="canonical"]');
    if (link) {
      defaultData = Object.assign({}, defaultData, {
        canon: link.href
      });
    }
  }

  //demographics, age, gender, birthYear
  ['a', 'g', 'y'].forEach(key => {
    if (demographics && demographics[key]) {
      defaultData = Object.assign({}, defaultData, { [key]: demographics[key] });
    }
  });

  if (badActor) {
    defaultData = Object.assign({}, defaultData, { ba: true });
  }

  const jotData = Object.assign({}, defaultData, data);
  const d = encodeURIComponent(JSON.stringify(jotData));
  const j = encodeURIComponent(jotConfig[space].id);
  const n = encodeURIComponent(jotConfig[space].sequence++);
  const s = encodeURIComponent(space);
  const t = encodeURIComponent(type);
  const jotUrl = `${jotConfig[space].URL}?j=${j}&n=${n}&s=${s}&t=${t}&d=${d}`;

  // FIXME: this fetch does not work in IE due to cors issues. Added catch to avoid breakage in IE
  fetch(jotUrl, { mode: 'no-cors' })
    .catch((e) => handleError(ErrorState.None, e));

  //update jotConfig in store
  return { jotConfig };
};
