/**
 * This wrapper lazy-loads DOMPurify library.
 *
 * @param toPurify {string}
 * @param purifyOptions {{}=null}
 * @param force {boolean=true}
 * @returns {Promise<T>}
 */
const toDOMPurifyLazy = (toPurify, purifyOptions, force = false) => {
  return import(/* webpackChunkName: "csw-vendor-dompurify" */ 'dompurify')
    .then(module => {
      const DOMPurify = module.default;
      let purified;
      try {
        purified = DOMPurify.sanitize(toPurify, purifyOptions);
      } catch (e) {
        purified = force ? toPurify : null;
        //FIXME: do we want to log on the api when something there is an xss clobbering error?
        //eslint-disable-next-line
        console.log('toDOMPurifyLazy() failed', e, toPurify);
      }
      return purified;
    });
};

export default toDOMPurifyLazy;
