import { envConfig } from 'config';
import { version as pv } from 'package.json';

const resolveCdn = (filePath) => {
  if (!filePath || process.env.NODE_ENV === 'development') {
    return filePath ? `${filePath}?pv=${pv}` : null;
  }

  const { cdn } = envConfig;

  return `${cdn}${filePath}?pv=${pv}`;
};

export default resolveCdn;
