import { isNullOrUndefOrEmpty } from 'utils';

/**
 *
 * @param focusRef {MutableRefObject}
 * @returns {false|setTimeout}
 */
const onFocusRef = (focusRef) => {
  return !isNullOrUndefOrEmpty(focusRef) &&
    //Wrapping the focusRef call with a setTimeout due to css hide-show of elements that are only visible on focus,
    // the setTimeout forces the render cycle to to allow the elements to be visible before focusing
    setTimeout(() => {
      focusRef?.current?.focus();
    });
};

export default onFocusRef;
