import { addScript, addStyleSheet, isNullOrUndefOrEmpty, resolveCdn } from 'utils';
import { version as pv } from 'package.json';
import { DelayTimer, FileNamePrefix } from 'consts';
import { envConfig } from 'config';

const configureEditableWidget = (instanceId, bootstrap = {}) => {
  if (isNullOrUndefOrEmpty(instanceId) || isNullOrUndefOrEmpty(bootstrap) || bootstrap === {}) {
    return;
  }

  const { isEditable } = envConfig;
  const { id: targetId, oid } = bootstrap;
  const isMetaTarget = targetId !== oid;
  const docHead = document.getElementsByTagName('head')[0];

  if (isEditable && docHead) {
    // NOTE: Only render admin tools for specific widget if renderAdminTools was loaded/used for another widget.
    // This will prevent useless re-rendering of admin tools on pages with multiple widgets.
    const widgetQueries = [`div[id][data-civicscience-widget='${oid}']`,
      `div[id][data-civicscience-portal='${oid}']`,
      `div[id='${instanceId}']`];

    const editablePositionCssPath = resolveCdn(`csw-editable-tools.${pv}.css`);
    const editablePositionPath = resolveCdn(`csw-editable-tools.js`);
    addStyleSheet(`${editablePositionCssPath}`, `${FileNamePrefix.EditablePositionCss}file`, docHead);

    addScript(`${editablePositionPath}`,
      `${FileNamePrefix.EditablePositionScript}file`, docHead, false);

    //Delay added, to make sure admin tools factory was loaded before accessing.
    setTimeout(() => {
      const hasRenderer = (window.civicscience && window.civicscience.renderAdminTools);
      hasRenderer && window.civicscience.renderAdminTools(widgetQueries.join(','),
        isMetaTarget ? targetId : null);
    }, DelayTimer.AdminToolRenderDelay);
  }
};

export default configureEditableWidget;
