const windowPop = (popUrl,
                   popTarget = null,
                   width = null,
                   height = null,
                   top = null,
                   left = null,
                   popExtraParams = {}) => {
  const popWidth = width || 600;
  const popHeight = height || 480;
  const popLeft = left || (window.innerWidth / 2 - popWidth / 2 + window.screenX);
  const popTop = top || (window.innerHeight / 2 - popHeight / 2 + window.screenY);
  const mergedExtraParams = Object.assign({},
    { scrollbars: 'no', location: 'no', resizeable: 'no', status: 'no', toolbar: 'no' },
    popExtraParams);
  const extraParamsStr = Object.keys(mergedExtraParams).map((k) => `${k}=${mergedExtraParams[k]}`).join(', ');

  const popParamStr = `${extraParamsStr}, width=${popWidth}, height=${popHeight}, top=${popTop}, left=${popLeft}`;
  const newWindow = window.open(popUrl, (popTarget || ''), popParamStr);

  window.focus && newWindow && newWindow.focus();
};

export default windowPop;
