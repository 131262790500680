import { DISPATCH_EVENTS_PREFIX, DispatchEvents } from 'consts';

const benn = () => {
  if (typeof window.CSadinit !== 'function'
    || typeof window.startCheckHeight !== 'function'
    || !/[?&]benn_siteid=/.test(window.location.search)) {
    return;
  }

  const container = document.getElementById('cspoll-content');

  // force a resize when these widget events occur
  const RESIZE_EVENTS = [
    DISPATCH_EVENTS_PREFIX + DispatchEvents.FrameResize,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.BeforeFetchingQuestions,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.BeforeAskingQuestions,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.QuestionAnswered,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.AfterAskingQuestions,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.BeforeShowingResults,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.ResultDeparted,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.AfterShowingResults
  ];

  // resize and refresh the ad when these events occur
  const AD_REFRESH_EVENTS = [
    DISPATCH_EVENTS_PREFIX + DispatchEvents.QuestionAsked,
    DISPATCH_EVENTS_PREFIX + DispatchEvents.ResultArrived
  ];

  // invoke BENN functions in response to widget events
  RESIZE_EVENTS.forEach(eventName => {
    container.addEventListener(eventName, () => window.startCheckHeight());
  });
  AD_REFRESH_EVENTS.forEach(eventName => {
    container.addEventListener(eventName, () => window.CSadinit());
  });

  // initial resize for good measure
  window.doc_dimensions = window.doc_dimensions || {};
  window.startCheckHeight();
};

export default benn;
