import { isArray } from 'utils';

const arrayUniqueBy = (arr, uniqueByParam) => {
  return isArray(arr)
    ? arr.filter((item, pos, array) => {
      return array.map((mapItem) => {
        return mapItem[uniqueByParam];
      }).indexOf(item[uniqueByParam]) === pos;
    })
    : arr;
};

export default arrayUniqueBy;
