import { isNullOrUndef, isNullOrUndefOrEmpty, isString } from 'utils';

const ContentRecUrlStorageKey = 'csw-content-rec-url';

const getRecommendedLinkStorage = (urlContext, deleteKeyAfterRetrieve = true) => {
  let keyValue;
  try {
    keyValue = (!isNullOrUndef(window.localStorage) && window.localStorage.getItem(ContentRecUrlStorageKey))
      || undefined;

    keyValue && deleteKeyAfterRetrieve && window.localStorage.removeItem(ContentRecUrlStorageKey);
  } catch (_) {
    // ignore
  }

  //NOTE: Need to strip URL protocol ('http/s:') for comparison with urlContext
  // Added try/catch safety to make sure url being passed has protocol before parsing as a new URL()
  let keyValueWithoutProtocol;
  try {
    keyValueWithoutProtocol = (isString(keyValue) && keyValue.startsWith('http'))
      ? keyValue.replace(new URL(keyValue).protocol, '')
      : undefined;
  } catch (_) {
    // ignore
  }

  //NOTE: ONLY Return keyValue IF urlContext matches keyValue without the protocol, ELSE return undefined
  return (!isNullOrUndefOrEmpty(keyValueWithoutProtocol)
    && isString(urlContext)
    && urlContext.startsWith(keyValueWithoutProtocol)) ? keyValue : undefined;
}

const setRecommendedLinkStorage = (contentRecUrl) => {
  try {
    !isNullOrUndef(window.localStorage) && window.localStorage.setItem(ContentRecUrlStorageKey, contentRecUrl);
  } catch (_) {
    // ignore
  }
};

export default {
  getRecommendedLinkStorage,
  setRecommendedLinkStorage
};
