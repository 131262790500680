import { arrayIncludes } from 'utils';

const handleKeyCodeTriggers = (event, keyCodeTriggers = [], callback) => {
  const { keyCode } = event;

  if (keyCode && arrayIncludes(keyCodeTriggers, keyCode) && callback) {
    return callback();
  }

  return null;
};

export default handleKeyCodeTriggers;
