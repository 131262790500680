import CaptionType from 'consts/CaptionType';

const CaptionStatic = [
  {
    type: CaptionType.Privacy, body: 'CAPTION_PRIVACY_1', translateBody: true
  },
  {
    type: CaptionType.Inspire, body: 'CAPTION_INSPIRE_1', translateBody: true
  },
  {
    type: CaptionType.Inspire, body: 'CAPTION_INSPIRE_2', translateBody: true
  },

];

export default CaptionStatic;
