import { WidgetState } from 'consts';
import { isArray, toObject } from 'utils';

/**
 * getDeckState - returns object that defines/wraps the current state of the deck of
 * cards(question/result/finish/etc) in the widget. This is useful for components needing to know
 * where in the widget flow during certain conditions with widgetState, questionData, and results.
 *
 * @param widgetState {number}
 * @param questionsData {object}
 * @param questionIndex {number}
 * @param results {array}
 * @param resultIndex {number}
 * @returns {{askableId: (number|string|null),
 * hasResults: boolean, resultActive: boolean, currentResult: (object|undefined),
 * hasQuestions: boolean, questionActive: boolean, questionLoadingActive: boolean, finishActive: boolean,
 * introActive:boolean, creSlideActive:boolean, currentQuestion: (object|undefined)}}
 */
const getDeckState = ({
  widgetState, questionsData= {}, questionIndex, results= [],
  resultIndex
}) => {
  const questionActive = widgetState === WidgetState.Questions;
  const questionLoadingActive = widgetState === WidgetState.QuestionsLoading;
  const creSlideActive = widgetState === WidgetState.ContentRecSlide;
  const finishActive = widgetState === WidgetState.Finish;
  const resultActive = widgetState === WidgetState.Results;
  const introActive = (widgetState === WidgetState.QuestionsNone
    || widgetState === WidgetState.TargetAdmin
    || widgetState === WidgetState.QuestionAdmin
    || widgetState === WidgetState.BlacklistAdmin);
  const { questions } = toObject(questionsData);
  const hasQuestions = isArray(questions) && questions.length > 0;
  const hasCurrentQuestion = hasQuestions && questionIndex < questions.length;
  const currentQuestionId = (questionActive && hasCurrentQuestion)
    ? questions[questionIndex].id : null;
  const currentQuestion = questionActive
    ? questionsData.questions.find(q => q.id === currentQuestionId) : {};
  const hasResults = isArray(results) && results.length > 0 && resultIndex >= 0;
  const currentResult = resultActive && hasResults && results[resultIndex];
  const { id: currentResultId } = toObject(currentResult);
  const askableId = currentQuestionId || currentResultId;

  return {
    askableId,
    hasQuestions,
    questionActive,
    questionLoadingActive,
    currentQuestion,
    hasResults,
    introActive,
    resultActive,
    currentResult,
    finishActive,
    creSlideActive
  };
};

export default getDeckState;
