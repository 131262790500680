import fetchJsonp from 'fetch-jsonp';
import { DispatchEvents, Natures, QuestionType, SubmitState, TextInputLength, WidgetState } from 'consts';
import {
  arrayIncludes,
  dispatchEvent,
  gameUtils,
  getQuestionIndexById,
  getTextRegEx,
  isEmpty,
  isNullOrUndefOrEmpty,
  isQuestionValidToSubmit,
  toObject,
  isObject,
  demographicsStorage,
} from 'utils';
import { envConfig } from 'config';

export const getResultsUrl = (answerUrl, optionParams, dateParam) => {
  const { majorVersion } = envConfig;
  return `${answerUrl}&${optionParams}&mv=${majorVersion}&_=${dateParam || Date.now()}`;
};

export const getAddedResultResponseCount = (store, toAdd = 0) => {
  if (!store) {
    return toAdd;
  }
  //re-fetch from store to ensure atomicity
  const { resultResponseCount } = store.getState();
  return resultResponseCount + toAdd;
};

export const flattenResults = (results = []) => {
  return [].concat.apply([], results);
};

const addStaticCaption = (store, staticCaptions = []) => {
  const random = Math.floor(Math.random() * staticCaptions.length);
  const caption = staticCaptions.splice(random, 1);

  store.setState({staticCaptions});
  return caption[0];
};

export const getResults = ({
  store,
  state,
  question = {},
  questionsData,
  currQuestionId,
  skip = false
}) => {
  const { apiTimeoutMS } = envConfig;
  const { config, readOnly, instanceId, gameEnabled, staticCaptions = [] } = state;
  const { rows, ta, answerUrl, type } = question;
  const { d: demographics } = config;
  const resultsDisabled = arrayIncludes(config['natures'], Natures.ResultsDisabled);

  //FIXME: zip lengths are hardcoded in consts for now, but should be definited by the question from the server
  const validationRegEx = getTextRegEx(TextInputLength.ZipMin, TextInputLength.ZipMax);
  //FIXME: isQuestionValid is used here to prevent submissions that seem to be caused by QuestionList component
  // lifecycle which had been double-submitting. Most likely, we should move getResults out of QuestionsList and into a
  // HOC like Widget.js and have only one validation check there.
  const isQuestionValid = isQuestionValidToSubmit(question, questionsData.questions, currQuestionId, validationRegEx);

  const stateInit = {
    submitState: SubmitState.NotReady,
  };

  if (isQuestionValid || skip === true) {
    //flag as not ready so we don't double submit
    store.setState(stateInit);
  } else {
    //NOTE: Do not proceed if question is invalid
    return {
      submitState: SubmitState.NotReady,
    };
  }

  //collect option answer values into a url parameter string
  const params = [];
  //goofy concat syntax instead of flatMap() to avoid polyfill/shim
  const options = (rows) ? [].concat.apply([], rows.map((row) => {
    return row.options;
  })) : question.options;

  // NOTE: Text questions do not have options or rows.
  !!options && options.forEach(option => {
    const { selected, ydata, ndata } = option || {};
    const p = (selected) ? ydata : ndata;
    !isEmpty(p) && params.push(p);
  });

  //text answer
  type === QuestionType.Text && !!ta && params.push(`ta=${ta}`);

  //read-only
  !!readOnly && params.push('ro=1');

  const optionParams = params.join('&');
  const indexOfSubmitQuestionId = getQuestionIndexById(questionsData.questions, currQuestionId);
  const nextQuestionIndex = indexOfSubmitQuestionId + 1;
  const isLastQuestion = (nextQuestionIndex >= questionsData.sessionLength);

  if (isLastQuestion) {
    //this is our last question submit, so now we must prepare to call getFinish
    const ids = questionsData.questions.map((question) => {
      return question.id;
    });
    dispatchEvent(instanceId, this, DispatchEvents.AfterAskingQuestions,
      false, { ids });
    store.setState({ widgetState: WidgetState.Finish })
  } else {
    //update question index
    store.setState({ questionIndex: nextQuestionIndex });
  }

  // GAMIFICATION
  instanceId && gameUtils.increaseDailyQuestionAnsweredCount(instanceId, gameEnabled, isLastQuestion);

  const resultsUrl = getResultsUrl(answerUrl, optionParams);

  if (skip) {
    return {
      resultResponseCount: getAddedResultResponseCount(store, 1)
    };
  }

  return fetchJsonp(resultsUrl, { timeout: apiTimeoutMS })
    .then(res => res.json())
    .then(json => {
      const { results, finishParams } = store.getState();
      const { hidden, finishData, results: jsonResults, d: resultDemographics } = json;

      dispatchEvent(instanceId, this, DispatchEvents.ResultsReceived,
        false, { results: jsonResults });

      const jsonResult = toObject(jsonResults[0]);
      const { caption } = jsonResult;

      if (isNullOrUndefOrEmpty(caption) && staticCaptions.length > 0) {
        jsonResult['caption'] = addStaticCaption(store, staticCaptions);
      }

      const previousResultExistsIndex = results.findIndex((result) => result.id === jsonResult.id);
      const previousResultDoesNotExist = previousResultExistsIndex === -1;//jsonResult DOES NOT EXIST in state results array

      if (previousResultDoesNotExist && !hidden) {
        results.push(jsonResults);//add jsonResult to store results array if result is NOT hidden
        finishParams.push(finishData);//add finishData to store finishParams array if result is NOT hidden
      } else {
        results[previousResultExistsIndex] = jsonResult; //replace result with jsonResult, if the jsonResult DOES EXIST in state results array
        finishParams[previousResultExistsIndex] = finishData; //if finishData already exists in finishParams, replace with jsonResult finishData
      }

      //flatten array
      const flatResults = resultsDisabled ? [] : flattenResults(results);

      const toStore = {
        results: flatResults,
        finishParams
      };

      //NOTE: If result already exists, do not increment/update state resultResponseCount
      if (previousResultDoesNotExist) {
        toStore.resultResponseCount = getAddedResultResponseCount(store, 1);
      }
      //if we got demographic data returned from the results endpoint, merge it with the existing demographic data
      //NOTE we may remove this once we have a wider solution for GAM targeting
      if (isObject(resultDemographics) && Object.keys(resultDemographics).length > 0) {
        const mergedDemographics = Object.assign({}, demographics, resultDemographics);
        toStore.config = Object.assign({}, config, { d: mergedDemographics });
        demographicsStorage.mergeLocalStorageDemographics(resultDemographics);
      }

      return toStore;
    })
    .catch(() => {
      return {
        resultResponseCount: getAddedResultResponseCount(store, 1)
      };
    });
};
