import React from 'react';
import ReactDOM from 'react-dom';
import { getStore } from 'store';
import { Provider } from 'unistore/preact';
import { DispatchEvents, DISPATCH_EVENTS_PREFIX, DOMSelector } from 'consts';
import { isNullOrUndef, isNullOrUndefOrEmpty, getTargetId, createCustomEvent } from 'utils';
import App from 'components/App';

// Enable preact devtools for proptype validation
if (process.env.NODE_ENV === 'development') {
  require('preact/debug');
}

//TODO look up meta tag for API override hostname
const hostnameMeta = document.querySelector('meta[name="civicscience:jspoll-hostname"]');
const hostnameOverride = hostnameMeta
  ? hostnameMeta.getAttribute('content')
  : null;

const buildWidgetContainer = (container, index, targetOverride = null, userId = null,
  articleUrlOverride = null, forceDarkModeOverride = false) => {
  const { dataset, id } = container;
  const articleUrl = articleUrlOverride || dataset['civicscienceArticleUrl'];

  // When widgetContainer is visible we trigger a delayed resize event so that the iFrame can resize itself,
  // now that it's parent is visible
  const widgetIntersectObserver = new IntersectionObserver(() => {
    const forceResizeEventName = `${DISPATCH_EVENTS_PREFIX}${DispatchEvents.ForceFrameResize}`;
    const customFrameResizeEvent = createCustomEvent(forceResizeEventName, true);
    window.dispatchEvent(customFrameResizeEvent);
  }, {
    root: null,
    rootMargin: '0px',
    threshold: 1.0
  });
  widgetIntersectObserver.observe(container);
  const store = getStore();
  const storeOverrides = {};
  if (id) {
    storeOverrides.instanceId = id;
  }
  if (hostnameOverride) {
    storeOverrides.hostname = hostnameOverride;
  }
  if (userId) {
    storeOverrides.userId = userId;
  }
  if (articleUrl) {
    storeOverrides.articleUrl = articleUrl;
  }

  (storeOverrides !== {}) && store.setState(storeOverrides);
  const targetId = getTargetId(dataset, targetOverride);
  //if target is empty, silently fail
  if (isNullOrUndefOrEmpty(targetId)) {
    return;
  }

  const appStoreProvider = () => {
    const disableResizable = dataset['civicscienceDisableResizable'];
    const theme = dataset['civicscienceTheme'];
    const forceDarkMode = forceDarkModeOverride || dataset['civicscienceForceDarkMode']  === 'true';
    return (
      <Provider store={store}>
        <App targetId={targetId}
             disableResizable={disableResizable === 'true'}
             theme={theme}
             forceDarkMode={forceDarkMode}
             instanceId={id}
             appIndex={index}/>
      </Provider>
    );
  };

  ReactDOM.render(appStoreProvider(), container);
};

const csWidgetContainers = document.querySelectorAll(DOMSelector.widgetWrapper);
csWidgetContainers.forEach((container, index) => buildWidgetContainer(container, index));

// v4 factory method
// usable via:
// civicscience.widget({
//   target: '000',
//   container: '#civsci-id-1234567890',
//   userId: `myUserId`,
//   articleUrl: `https://url-to-article`,
//   forceDarkMode: false
// });
let csWidgetIndex = 0;
const widget = ({ target, container, userId, forcedIndex, articleUrl, forceDarkMode = false }) => {
  //strip #
  container = container.replace('#', '');
  const containerEl = document.getElementById(container);

  if (!isNullOrUndefOrEmpty(containerEl) && !isNullOrUndefOrEmpty(target)) {
    buildWidgetContainer(containerEl, forcedIndex ? forcedIndex : csWidgetIndex, target,
      userId, articleUrl, forceDarkMode);
    if (isNullOrUndef(forcedIndex)) {
      csWidgetIndex += 1;
    }
  }
};

// expose the factory globally
(window.civicscience || (window.civicscience = {})).widget = widget;
