import { getTopmostParent } from 'utils';
// import { getTopmostParent, toObject } from 'utils';

// const inIframe = () => {
//   try {
//     return window.self !== window.top;
//   }
//   catch (e) {
//     // *some* browsers throw exceptions on cross-domain here
//     return true;
//   }
// };

const getTopmostPageDetail = () => {
  //FIXME: SOF-1937 this section commented out for now, until we feel it is needed for certain publishers
  //
  // // iframe solutions
  // if (inIframe()) {
  //   // safeframe extraction
  //   const w = window;
  //   const sf = w['$sf'];
  //   const { ext } = toObject(sf);
  //
  //   if (window.parent == top && ext) {
  //     //nested immediately under host, safeframe available
  //     if (ext.hostURL && typeof ext.hostURL === 'function') {
  //       //Yahoo specific function
  //       return { url: ext.hostURL(), isFramed: true };
  //     }
  //     //TODO: future other scenarios potentially via ext.meta['key']
  //   }
  //   // intentionally fall through to code below
  // }

  const topmostParent = getTopmostParent();
  let url;
  let isFramed = false;
  try {
    url = topmostParent.location.href;
    if (url === undefined) {
      // Yeah, this is goofy, but it's the most illustrative way I could think of to code this.
      // Some browsers e.g. iOS 7+ report an error in the console when accessing a cross-origin window
      // location, but they don't actually *throw* a catchable capital-E Error for handling here. Instead
      // the unauthorized property access simply yields undefined and proceeds with the next line.
      throw new Error();
    }
  } catch (e) {
    isFramed = true;
    url = window.document.referrer;
  }
  return { url, isFramed };
};

export default getTopmostPageDetail;
