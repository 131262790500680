import { isNullOrUndefOrEmpty } from 'utils';

// @param {string} btnSelector: style selector to apply button styling changes
// @param {object} options: button style config
//    @param {string} bg - #hex color values for background-color style
//    @param {string} fg - #hex color values for color style
const getButtonColorStyles = (btnSelector, { bg, fg }) => {
  if (!btnSelector || (!bg && !fg)) {
    return null;
  }

  let btnColorStyles = '';
  const btnSelectorExtra = ':not(.csw-btn-icon):not(:disabled)';

  if (bg) {
    btnColorStyles += `
    ${btnSelector},
    ${btnSelector}${btnSelectorExtra}:hover,
    ${btnSelector}${btnSelectorExtra}:focus,
    ${btnSelector}${btnSelectorExtra}:active {
      background-color: ${bg}; border-color: ${bg};
    }
    `;
  }

  if (fg) {
    btnColorStyles += `
    ${btnSelector},
    ${btnSelector}${btnSelectorExtra}:hover {
      color: ${fg};
    }
    `;
  }

  return isNullOrUndefOrEmpty(btnColorStyles) ? null : btnColorStyles;
};

export default getButtonColorStyles;
