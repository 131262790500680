import { SubmitState } from 'consts';
import { isTextQuestionValidToSubmit } from 'utils';

export const onTextChanged = ({ state, question, text, validationRegEx }) => {
  const { questionsData = {}, questionIndex } = state;
  let updatedQuestionsData = Object.assign({}, questionsData);
  const currQuestionId = questionsData.questions[questionIndex].id;
  const isValid = question && question.id === currQuestionId &&
    isTextQuestionValidToSubmit(question, text, validationRegEx);

  updatedQuestionsData.questions = updatedQuestionsData.questions.map((q) => {
    if (question && question.id === currQuestionId && question.id === q.id) {
      q.ta = text;
    }
    return q;
  });

  const toState = { submitState: isValid ? SubmitState.Ready : SubmitState.NotReady };

  if (isValid) {
    toState.questionsData = Object.assign({}, updatedQuestionsData);
  }

  return toState;
};
