import { QuestionType } from 'consts';
import { arrayIncludes } from 'utils';

const getAllUnderlyingQuestionIds = (question) => {
  const { type, options, rows } = question;
  const isTextQuestion = type === QuestionType.Text;
  const qids = [];

  const addOptions = (optsToAdd) => {
    optsToAdd.forEach(o => {
      const { qid } = o;
      if (!arrayIncludes(qids, qid)) {
        qids.push(qid);
      }
    });
  };

  //NOTE: only push to qids [] when NOT a QuestionType.Text
  if (!isTextQuestion && options) {
    addOptions(options);
  } else if (!isTextQuestion && rows) {
    rows.forEach(r => {
      addOptions(r.options);
    });
  }

  return qids;
};

export default getAllUnderlyingQuestionIds;
