import { isArray, isNullOrUndefOrEmpty, isString } from 'utils';

const isFlexMatrix = (question) => {
  if (isNullOrUndefOrEmpty(question)) {
    return false;
  }

  const { columns, rows } = question;
  // NOTE: @viewportWidth is the parent window width, not the width of the Widget iFrame
  const viewportWidth = window.innerWidth;
  const colCountCutoff = 3;
  const viewportWidthCutoff = 400;
  const wordCharCountCutoff = 5;
  const colLabelTextCharCountCutoff = 14;
  const rowLabelTextCharCountCutoff = 19;
  let isFlex = (viewportWidth < viewportWidthCutoff); // Basic screen width test for initial value

  //Test col and col text lengths
  if (!isFlex && columns && isArray(columns)) {
    if (columns.length > colCountCutoff) {
      isFlex = true;
    } else if (columns.length <= colCountCutoff) {
      for (let ci = 0; ci < columns.length; ci++) {
        if (columns[ci] && isString(columns[ci].text)) {

          if (columns[ci].text.length > colLabelTextCharCountCutoff) {
            isFlex = true;
          } else if (columns.length === colCountCutoff) {
            const columnTextArr = columns[ci].text.split(' ');

            for (let cti = 0; cti < columnTextArr.length; cti++) {
              if (columnTextArr[cti].length > wordCharCountCutoff) {
                isFlex = true;
                break;
              }
            }
          }

        }
      }
    }

  }

  //Test row text length
  if (!isFlex && rows && isArray(rows)) {
    for (let ri = 0; ri < rows.length; ri++) {
      if (rows[ri] && isString(rows[ri].text) && rows[ri].text.length > rowLabelTextCharCountCutoff) {
        isFlex = true;
        break;
      }
    }
  }

  return isFlex;
};

export default isFlexMatrix;
