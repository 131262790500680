import { isNullOrUndefOrEmpty } from 'utils';

/**
 *
 * @param value {any}
 * @returns {Object}
 */
const toObject = (value) => {
  return (typeof value !== 'object') || isNullOrUndefOrEmpty(value) ? {} : value;
};

export default toObject;
