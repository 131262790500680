const addScript = (scriptUrl, id, parentNode, defer = false) => {
  if (!scriptUrl || !parentNode || !id || document.getElementById(id)) {
    return;
  }
  const scriptToAdd = document.createElement('script');
  scriptToAdd.setAttribute('id', id);
  scriptToAdd.setAttribute('src', scriptUrl);
  scriptToAdd.setAttribute('type', 'text/javascript');
  scriptToAdd.setAttribute('charset', 'UTF-8');
  defer && scriptToAdd.setAttribute('defer', 'true');
  parentNode.appendChild(scriptToAdd);
};

export default addScript;
