import createStore from 'unistore';
import { ErrorState, LocaleState, ComplianceState, SubmitState, WidgetState } from 'consts';
import { envConfig } from 'config';

export const getStore = () => {
  const { hostname } = envConfig;

  return createStore({
    widgetState: WidgetState.Init,
    errorState: ErrorState.None,
    localeState: LocaleState.LocaleNotLoaded,
    complianceState: ComplianceState.Off,
    submitState: SubmitState.NotReady,
    hostname,
    userId: '',
    urlContext: '',
    articleUrl: null,
    instanceId: '',
    backupAlias: '',
    bootstrap: {},
    config: {},
    questionsData: {},
    resubmitIds: [],         //askable codes for questions the user clicked back button to resubmit answer
    submitIds: [],        //submitted askable codes
    servedIds: [],        //served askable codes
    questionIndex: 0,
    resetQuestions: false,
    results: [],
    resultResponseCount: 0,
    finishParams: [],
    finishData: {},
    excludeParams: [],       //question ids to exclude
    resultIndex: 0,
    jotConfig: {},
    adEngageEnabled: false,
    gameEnabled: false,// GAMIFICATION
    readOnly: false,
    widgetInteractable: false,
    widgetInScroll: false,
    darkMode: false,
    shareableLink: null,
    trackingPixelsInserted: false
  });
};
