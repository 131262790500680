/**
 * Determine if there is an opt-out option, and if it is selected.
 * @param {*} options
 * @returns true if there is a selected opt-out, otherwise false
 */
const isOptOutSelected = (options = []) => {
  return options.filter(filteredOption => filteredOption.isOptOut && filteredOption.selected).length > 0;
};

export default isOptOutSelected;
