import { isArray } from 'utils';

const sortBy = (arrayToSort, key, order = 'asc') => {
  if(!isArray(arrayToSort) || !key) {
    return arrayToSort;
  }
  const sortedArray = arrayToSort.slice();

  return sortedArray.sort((a, b) => {
    const hasProp = (obj, propKey) => Object.prototype.hasOwnProperty.call(obj, propKey);
    if (!hasProp(a, key) || !hasProp(b, key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  });
}

export default sortBy;
