import { isNullOrUndef } from 'utils';

const ITEM_NAME = '__iqpbb'; // crappy name but needed for legacy stored aliases
let alias = null;

export const clearLocalBackupAlias = () => {
  if (isNullOrUndef(window.localStorage)) {
    return alias;
  }
  try {
    window.localStorage.removeItem(ITEM_NAME);
    alias = null;
  } catch (_) {
    // ignore
  }
  return alias;
};

/**
 * Get or create localStorage alias, optionally using provided `aliasString` for value.
 *
 * @param {string} [aliasString=null] - alias string value to utilize.
 * @returns {string} alias or empty string
 */
const getLocalBackupAlias = (aliasString = null) => {
  if (!isNullOrUndef(alias) || isNullOrUndef(window.localStorage)) {
    return alias;
  }
  try {
    alias = window.localStorage.getItem(ITEM_NAME);
    if (alias == null) {
      const maxInt = 4294967296;
      alias = aliasString ||
              'local/' + Math.floor(Math.random() * maxInt) + '.' + Math.floor(Math.random() * maxInt);
      window.localStorage.setItem(ITEM_NAME, alias);
    }
  } catch (_) {
    // ignore
  }
  return alias || '';
};

export default getLocalBackupAlias;
