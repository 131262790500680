import { ComplianceState } from 'consts';
import { envConfig } from 'config';
import { isNullOrUndef, isNullOrUndefOrEmpty, jsonToUri } from 'utils';

const { complianceCheck, protocol, widgetManageAnswersEndpoint } = envConfig;

const DefaultComplianceVersion = '0';
const DefaultCompliancePrefix = 'ballotbox_pp_';

const buildManageAnswersUrl = (hostname, compliance, alias) => {
  //hostname is passed in as it is dynamically retrieved from API and stored in state
  const manageAnswersUrlBase = `${protocol}${hostname}${widgetManageAnswersEndpoint}`;
  const { version } = compliance;
  const respondentParamObj = { alias };
  respondentParamObj[getPrivacyPolicyKey(version)] = getComplianceLocalStorage(version) ? '1' : '0'; //NOTE: binary boolean
  const respondentParams = jsonToUri(respondentParamObj);

  return `${manageAnswersUrlBase}?${respondentParams}`;
};

const getPrivacyPolicyKey = (version) => {
  return `${DefaultCompliancePrefix}${version ? version : DefaultComplianceVersion}`;
};

const needsComplianceReset = (compliance, complianceState) => {
  return complianceCheckOn(compliance) && complianceState === ComplianceState.NotAccepted;
};

const needsComplianceResponse = (compliance, complianceState) => {
  return complianceCheckOn(compliance) && !complianceStateOk(complianceState);
};

const getComplianceLocalStorage = (version) => {
  const complianceId = getPrivacyPolicyKey(version);

  return !isNullOrUndef(window.localStorage) && window.localStorage.getItem(complianceId);
}

/**
 * NOTE: complianceStorageOk checks local storage along with what is passed from config to see if compliance has been accepted.
 * WARNING: This is not a getter method. If compliance object is passed, but local storage is not set, set local storage compliance.
 * @param compliance {object}
 * @return {boolean}
 */
const complianceStorageOk = (compliance) => {
  if (!complianceCheckOn(compliance)) {
    return false;
  }

  const { version, accepted } = compliance;
  const complianceLocalStorage = getComplianceLocalStorage(version);
  const complianceAccepted = accepted || !!complianceLocalStorage;
  const complianceOk = compliance && complianceAccepted;

  //NOTE: in the case where compliance config identifies the user
  // previously accepted but localStorage is missing the value, call to setComplianceStorage
  complianceOk && !complianceLocalStorage && setComplianceStorage(version, accepted);

  return complianceOk;
};

const complianceCheckOn = (compliance) => {
  return complianceCheck && !!compliance && compliance.enabled;
};

const complianceUrl = (config = {}) => {
  const { compliance } = config;

  return compliance ? compliance.url : null;
};

const complianceStateOk = (complianceState) => {
  return complianceCheck
    ? (complianceState === ComplianceState.Accepted || complianceState === ComplianceState.Off)
    : true;
};

const setComplianceStorage = (version, acceptedVal = false) => {
  if (isNullOrUndefOrEmpty(version) || isNullOrUndef(window.localStorage)) {
    return false;
  }

  const complianceId = getPrivacyPolicyKey(version);

  try {
    acceptedVal ? window.localStorage.setItem(complianceId, 'true') : window.localStorage.removeItem(complianceId);
  } catch (_) {
    // ignore
  }

  return acceptedVal;
};

export default {
  DefaultComplianceVersion,
  DefaultCompliancePrefix,
  buildManageAnswersUrl,
  needsComplianceReset,
  needsComplianceResponse,
  complianceCheckOn,
  complianceStorageOk,
  complianceStateOk,
  complianceUrl,
  getPrivacyPolicyKey,
  getComplianceLocalStorage,
  setComplianceStorage
};
