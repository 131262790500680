/* eslint-disable */
// FIXME: clean up and remove unused imports for eslint, when lazy loading works.
import { addStyle, addStyleSheet, getButtonColorStyles, isNullOrUndefOrEmpty, resolveCdn } from 'utils';
import { FileNamePrefix } from 'consts';
import { version as pv } from 'package.json';

// NOTE: sanitizeStyles is a basic clean of potentially xss vulnerable styling. :cross-fingers:
const sanitizeStyles = (stylesStr) => {
  if (typeof stylesStr !== 'string') {
    return null;
  }

  //Cleaning RegEx
  const doubleSpace = new RegExp('  +', 'gi');
  const allTags = new RegExp('(<([^>]+)>)', 'gi');
  const disallowed = new RegExp('/>|/ +>|<=|script|javascript|onerror|<img|alert|expression' +
    '|<input|<form|@import|@ +import|<object|<embed' +
    '|url +(([^)]+))|url(([^)]+))| on*=|=', 'gi');

  const trimChar = (removeFrom, whichChar, escapeChar) => {
    return removeFrom.replace(new RegExp(` +${whichChar}`, 'gi'), whichChar)
      .replace(new RegExp(`${whichChar} +`, 'gi'), escapeChar || whichChar);
  };

  let cleanStyles = stylesStr.replace(doubleSpace, ' ');
  cleanStyles = trimChar(cleanStyles, '<', '&lt;');
  cleanStyles = trimChar(cleanStyles, '/');
  cleanStyles = trimChar(cleanStyles, '=');
  cleanStyles = trimChar(cleanStyles, ':');
  cleanStyles = cleanStyles.replace(allTags, '')
    .replace(disallowed, '')
    .replace(doubleSpace, ' ');

  return cleanStyles;
};

//NOTE: hasStyleOverrides is a check to make sure partnerWidgetCss is an object instead of string,
// partnerWidgetCss could be a string or object depending on which Major Version is sent to target API
const hasStyleOverrides = (widgetCss) => {
  return !!widgetCss && typeof widgetCss === 'object';
};

// @param {string} btnSelector: style selector to apply button styling changes
// @param {object} colors: all color style options
//    @param {object} primary - color style object for primary buttons, contains { fg, bg }
//    @param {object} secondary - color style object for secondary buttons, contains { fg, bg }
//    @param {string} partner - #hex color values for background-color style for chart fills
const getColorStyles = (wrapperSelector, colors) => {
  if (!wrapperSelector || !colors || typeof colors !== 'object') {
    return null;
  }

  const { primary, secondary, partner } = colors;
  let updatedColorStyles = '';

  //FIXME: Currently the API returns an empty [] for primary|secondary|answer instead of null,
  // so we have to do type testing to make sure we can parse as an object.
  if (secondary && typeof secondary === 'object') {
    const secondaryBtnColors = getButtonColorStyles(`html.csw-html .csw-btns .csw-btn`, secondary);
    if (secondaryBtnColors) {
      updatedColorStyles += secondaryBtnColors;
    }
  }

  if (primary && typeof primary === 'object') {
    const primaryBtnColors = getButtonColorStyles(`html.csw-html .csw-btns .csw-btn.csw-btn-primary`, primary);
    if (primaryBtnColors) {
      updatedColorStyles += primaryBtnColors;
    }
  }

  if (partner && typeof partner === 'string') {
    updatedColorStyles += `
        ${wrapperSelector} .csw-option-result-item-fill.option-fill,
        ${wrapperSelector} .csw-r-mtrx-o-chart-fill { background-color: ${partner}; }
        ${wrapperSelector} .csw-i.csw-i-up-caret,
        ${wrapperSelector} .csw-i.csw-i-down-caret,
        ${wrapperSelector} .csw-i.csw-i-left-caret,
        ${wrapperSelector} .csw-i.csw-i-right-caret { border-top-color: ${partner}; }
        `;
  }

  return isNullOrUndefOrEmpty(updatedColorStyles) ? null : updatedColorStyles;
};

const sanitizedWidgetStyles = (partnerWidgetCss) => {
  if (!hasStyleOverrides(partnerWidgetCss)) {
    return null;
  }

  const { outer, header, font, fontSize, colors } = partnerWidgetCss;

  if (!outer && !header && !font && !fontSize && !colors) {
    return null
  }

  let widgetStyles = '';

  if (fontSize) {
    const cleanBaseFontSize = sanitizeStyles(fontSize);
    if (cleanBaseFontSize) {
      widgetStyles += `
      html.csw-html { font-size: ${cleanBaseFontSize}; }
      `;
    }
  }

  if (outer) {
    const cleanOuterStyles = sanitizeStyles(outer);
    if (cleanOuterStyles) {
      widgetStyles += `
      .csw { ${cleanOuterStyles} }
      `;
    }
  }

  if (font) {
    const cleanFontFamilyStyles = sanitizeStyles(font);
    if (cleanFontFamilyStyles) {
      widgetStyles += `
      html.csw-html, body.csw-body, .csw .font-default,
        .csw .font-special { font-family: ${cleanFontFamilyStyles}; }
      `;
    }
  }

  if (header) {
    const cleanHeaderStyles = sanitizeStyles(header);
    if (cleanHeaderStyles) {
      widgetStyles += `
      .csw .csw-header { ${cleanHeaderStyles} }
      `;
    }
  }

  if (colors) {
    const parsedColorStyles = getColorStyles('.csw', colors);
    const cleanColorStyles = sanitizeStyles(parsedColorStyles);
    if (cleanColorStyles) {
      widgetStyles += `
      ${cleanColorStyles}
      `;
    }
  }

  return isNullOrUndefOrEmpty(widgetStyles) ? null : `${widgetStyles}`;
};

const sanitizedAdEngageStyles = (partnerWidgetCss, id, wrapperSelector, parentNode) => {
  if (!id || !parentNode) {
    return null;
  }

  // addStyleSheet(resolveCdn(`csw-ae.${pv}.css`), `${FileNamePrefix.AdEngageCss}file`,
  //   parentNode);

  if (hasStyleOverrides(partnerWidgetCss)) {
    const { colors } = partnerWidgetCss;

    if (colors) {
      const updatedColorStyleOverrides = getColorStyles(`${wrapperSelector}`,
        colors);
      updatedColorStyleOverrides && addStyle(updatedColorStyleOverrides, `csw-ae-head-color-styles_${id}`,
        parentNode);
    }
  }
};

const sanitizedAdEngageHeadStyles = (headStyle, id, parentNode) => {
  if (isNullOrUndefOrEmpty(headStyle) || !id || !parentNode) {
    return null;
  }

  const cleanHeadStyle = sanitizeStyles(headStyle);
  cleanHeadStyle && addStyle(cleanHeadStyle, `csw-ae-head-styles_${id}`,
    parentNode);
};

export default {
  getColorStyles,
  hasStyleOverrides,
  sanitizeStyles,
  sanitizedWidgetStyles,
  sanitizedAdEngageStyles,
  sanitizedAdEngageHeadStyles
};
