const addStyleSheet = (sheetUrl, id, parentNode) => {
  if (!sheetUrl || !parentNode || !id || document.getElementById(id)) {
    return;
  }
  const styleSheet = document.createElement('link');
  styleSheet.setAttribute('id', id);
  styleSheet.setAttribute('href', sheetUrl);
  styleSheet.setAttribute('rel', 'stylesheet');
  parentNode.appendChild(styleSheet);
};

export default addStyleSheet;
