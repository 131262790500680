import { createCustomEvent } from 'utils';
import { DISPATCH_EVENTS_PREFIX } from 'consts';

const dispatchEvent = (instanceId, widget, name, cancelable, detail) => {
  if (!instanceId || !name) {
    return false;
  }

  const container = document.getElementById(instanceId);
  const updatedName = `${DISPATCH_EVENTS_PREFIX}${name}`;
  detail = detail || {};
  detail['instanceId'] = instanceId;//Always add instanceId to object
  //TODO: widget property is currently being passed as 'this' and probably should be something else.
  detail['widget'] = widget;

  const event = createCustomEvent(updatedName, cancelable, detail);

  return container.dispatchEvent(event);
};

export default dispatchEvent;
