/*
  Before, After, Left, and Right ad units are *always* shown next to the widget.
  Start, Mid, End, Results and Questions ad units display in place of the widget.
*/
const AdPlacement = {
  None: 'none',
  Before: 'before',
  After: 'after',
  Left: 'left',
  Right: 'right',
  Start: 'start',
  Mid: 'mid',
  End: 'end',
  Result: 'result',
  Question: 'question',
};

export default AdPlacement;
