import { removeUrlParam } from 'utils';

const removeUrlParams = (paramKeys = [], url) => {
  let cleanUrl = `${url}`;

  paramKeys.forEach((paramKey) => {
    cleanUrl = removeUrlParam(paramKey, cleanUrl);
  });

  return cleanUrl;
}

export default removeUrlParams;
