import { QuestionType } from 'consts';
import { getNumberOptionsSelected, getNumberRowsCompleted, isOptOutSelected, isTextQuestionValidToSubmit } from 'utils';

const isQuestionValidToSubmit = (question, questions, currQuestionId, validationRegEx) => {
  let isValid = false;
  const { options, checkbox, rows, type, id: questionId, ta } = question;
  const isCurrQuestion = currQuestionId === questionId;

  if (isCurrQuestion) {
    if (options) {
      const numSelected = getNumberOptionsSelected(options);
      if (type === QuestionType.Radio) {
        isValid = numSelected === 1;
      } else if (type === QuestionType.Checkbox) {
        //allow submit of checkbox group if opt out selected OR passes min/max check
        isValid = (numSelected !== 0 &&
          (isOptOutSelected(options) ||
          (numSelected >= checkbox['minChecked'] && numSelected <= checkbox['maxChecked'])));
      }
    } else if (rows) {
      const numRowsCompleted = getNumberRowsCompleted(rows);
      isValid = numRowsCompleted === rows.length;
    } else if (type === QuestionType.Text) {
      isValid = isTextQuestionValidToSubmit(question, ta, validationRegEx);
    }
  }

  return isValid;
};

export default isQuestionValidToSubmit;
