import { handleKeyCodeTriggers } from 'utils';
import { KeyboardEventKeyCode } from 'consts';

const handleEnterSpaceKey = (event, callback) => {
  return handleKeyCodeTriggers(event,
    [KeyboardEventKeyCode.Enter, KeyboardEventKeyCode.Space],
    callback);
};

export default handleEnterSpaceKey;
