const isInScrollView = ({ domNode, delayed = false, offsetTop = 0, offsetBottom = 0 }) => {
  if (!domNode) {
    return false;
  }

  const { top, bottom, width } = domNode.getBoundingClientRect();
  const hasWidth = width > 0;
  const isTopVisible = (top - offsetTop) >= 0 && (top + offsetTop) <= window.innerHeight;
  // NOTE: isAtBottomScroll is true if user has scrolled to bottom.
  const isAtBottomScroll = (window.innerHeight + (window.scrollY || window.pageYOffset)) >= document.body.offsetHeight;

  const isBottomVisible = isAtBottomScroll || ((bottom - offsetBottom) >= 0
    && (bottom + offsetBottom) <= window.innerHeight);

  return hasWidth && (delayed ? isTopVisible : (isTopVisible || isBottomVisible));
};

export default isInScrollView;
