const copyToClipboard = (clipboardDocument, textToCopy, onComplete) => {
  if (!textToCopy || !clipboardDocument) {
    return;
  }
  const hasClipboard = !!navigator.clipboard && !!navigator.permissions;

  if (hasClipboard) {//Newer browsers supporting Clipboard and Permissions API
    const permissionDescriptor = {};
    permissionDescriptor.name = 'clipboard-write';
    navigator.permissions.query(permissionDescriptor).then(result => {
      if (result.state === 'granted') {
        const copyComplete = () => {
          onComplete && onComplete();
        };

        const copyFail = () => {
          //Error: do nothing
        };

        navigator.clipboard.writeText(textToCopy).then(copyComplete, copyFail);
      }
    });
  } else { //Older browsers like IE11
    const copyPad = clipboardDocument.createElement('textarea');
    copyPad.setAttribute('readonly', 'readonly');
    copyPad.value = textToCopy;

    Object.assign(copyPad.style, {
      position: 'fixed',
      border: 'none',
      height: '0',
      bottom: '0',
      left: '0',
      opacity: '0',
      clip: 'rect(0, 0, 0, 0)'
    });

    clipboardDocument.body.appendChild(copyPad);
    copyPad.select();
    clipboardDocument.execCommand('copy');
    clipboardDocument.body.removeChild(copyPad);

    onComplete && onComplete();
  }
};

export default copyToClipboard;
