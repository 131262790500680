import { dispatchEvent, isArray } from 'utils';
import { DispatchEvents } from 'consts';

export const onAfterShowingResults = ({ state }) => {
  if (!state) {
    return;
  }

  const { instanceId, results } = state;
  const resultDetail = { length: isArray(results) ? results.length : 0 };

  dispatchEvent(instanceId, this, DispatchEvents.AfterShowingResults, false, resultDetail);

  return {};
};
