import { isArray } from 'utils';
import { jot } from 'actions/jot';
import { JotType } from 'consts';

export const onPreviousQuestion = ({ state }) => {
  if (!state) {
    return;
  }
  const { questionIndex, questionsData, resubmitIds = [], submitIds = [], servedIds = [] } = state;
  const previousIndex = questionIndex > 0 ? questionIndex - 1 : 0;
  const previousQuestion = questionsData?.questions[previousIndex];
  const currentQuestion = questionsData?.questions[questionIndex];
  const updatedSubmitIds = submitIds.filter(s => s !== previousQuestion.id);
  const updatedServedIds = servedIds.filter(s => (s !== previousQuestion.id) && (s !== currentQuestion.id));
  const updatedResubmitIds = resubmitIds.includes(previousQuestion.id)
    ? resubmitIds : [...resubmitIds, previousQuestion.id];

  const updatedQuestions = (questionsData?.questions ?? []).map((question, qi)=> {
    if (qi === questionIndex && !updatedResubmitIds.includes(question.id)) { //Must reset non-submitted/current question when back button is pressed, IF the question.id is not already part of the resubmitIds
      const questionUpdated = Object.assign({}, question);
      const { options, rows } = questionUpdated;

      const resetSelectedOptions = (optionArray = []) => optionArray.map((o) => {
        o.selected = false;
        return o;
      });

      if (isArray(rows) && rows.length > 0) {//Matrix/Spectrum
        questionUpdated.rows = questionUpdated.rows.map((row) => {
          row.options = resetSelectedOptions(row.options);
          return row;
        });
      } else if (isArray(options) && options.length > 0) {//Radio/Checkbox/Select
        questionUpdated.options = resetSelectedOptions(questionUpdated.options);
      }

      return questionUpdated;
    }

    return question;
  });

  const updatedQuestionsData = Object.assign({}, questionsData, {
    questions: updatedQuestions
  });

  jot({ state, space: 'poll', type: JotType.PreviousQuestion });

  return {
    questionIndex: previousIndex,
    submitIds: updatedSubmitIds,
    servedIds: updatedServedIds,
    resubmitIds: updatedResubmitIds,
    questionsData: updatedQuestionsData
  };
};
