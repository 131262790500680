import { AdPlatforms } from 'consts';

const isAdPlatformValid = (rootbeer, whichPlatform) => {
  if (!rootbeer || typeof rootbeer !== 'object') {
    return false;
  }

  const { platform, divId, zid, pid, width, height, placement, placementIndex } = rootbeer;

  const invalidBaseConditions = !platform || (platform && platform !== whichPlatform)
    || !width || !height || !/^\d+$/.test(width) || !/^\d+$/.test(height)
    || !placement || !/^start|before|after|right|left|end|mid|question|result$/.test(placement);

  const invalidExtraConditions = !zid || !pid;

  const validRuleset = [!invalidBaseConditions];
  if (whichPlatform !== AdPlatforms.msn &&
      whichPlatform !== AdPlatforms.taboola &&
      whichPlatform !== AdPlatforms.medianet) {
    validRuleset.push(!invalidExtraConditions);
  }
  if (whichPlatform === AdPlatforms.medianet) {
    validRuleset.push(!!divId)
  }
  if (placement === 'question' || placement === 'result') {
    validRuleset.push(placementIndex !== null);
  }

  const isValid = (rule) => rule === true;

  return validRuleset.every(isValid);
};

export default isAdPlatformValid;
