import fetchJsonp from 'fetch-jsonp';
import { envConfig } from 'config';
import { ErrorState, WidgetState, JotType } from 'consts';
import {
  getTopmostPageUrl,
  handleError,
  isNullOrUndefOrEmpty,
  getConfigUrl,
  recommendationUtils,
  toObject
} from 'utils';
import { jot } from 'actions/jot';

const { widgetBootstrapEndpoint } = envConfig;

const configToJotPollCreated = (targetId, state) => {
  const { urlContext } = toObject(state);

  jot({
    state,
    space: 'poll',
    type: JotType.Created,
    data: {
      target: targetId,
      //NOTE: If no content rec link, contentRecUrl value below is undefined, and eventually removed from the
      // data string in the request url created by JSON.stringify inside jot()
      contentRecUrl: recommendationUtils.getRecommendedLinkStorage(urlContext)
    }
  });
};


export const getBootstrap = ({ store, state, targetId, instanceId }) => {
  const topmostPageUrl = getTopmostPageUrl(state.articleUrl);
  const urlContext = topmostPageUrl.replace(/^[^:]+:/, '')
    .replace(/\/+$/, '');

  const toState = {
    instanceId: instanceId,
    bootstrap: {},
    widgetState: WidgetState.BootstrapLoading,
    urlContext
  };
  store.setState(toState);
  const { apiTimeoutMS } = envConfig;
  configToJotPollCreated(targetId, store.getState());

  return fetchJsonp(
    getConfigUrl(state, targetId, instanceId, widgetBootstrapEndpoint, urlContext, topmostPageUrl),
    { timeout: apiTimeoutMS })
    .then(res => res.json())
    .then(bootstrap => {
      const widgetState = WidgetState.BootstrapLoaded;
      const { hostnameOverride } = bootstrap || {};

      const updatedBootstrapState = {
        bootstrap,
        urlContext,
        widgetState
      };

      if (!isNullOrUndefOrEmpty(hostnameOverride)) {
        updatedBootstrapState.hostname = hostnameOverride;
      }

      return updatedBootstrapState;
    }).catch((e) => handleError(ErrorState.Bootstrap, e));
};
