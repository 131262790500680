import { evalNatures, toObject } from 'utils';

/**
 * Perform identity synchronization call(s) to CS idsync endpoint.
 *
 * Do not call unless compliance is enabled and has been accepted.
 *
 * @param {*} config target configuration
 * @param {string} hostname hostname in format `optional.hostname.com`
 */
const idsync = (config = {}, hostname) => {
  const { natures = [] } = config;
  const { muidEnabled } = evalNatures(natures);

  //Check if we are in a MUID extraction -> idsync scenario
  if (muidEnabled) {
    const docHead = document.head || document.getElementsByTagName('head')[0] || {};
    const { clientSettings } = toObject(docHead.dataset);

    if (clientSettings) {
      const clientSettingsJSON = (function (raw) {
        try {
          return JSON.parse(raw);
        } catch (err) {
          return {};
        }
      })(clientSettings);
      const muid = clientSettingsJSON.requestMuid;
      if (muid) {
        document.createElement('img').src = `https://${hostname}/idsync/3?p=ms&uid=${encodeURIComponent(muid)}`;
      }
    }
  }

  //Other future idsync scenarios go here
};

export default idsync;
