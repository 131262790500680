import { isNullOrUndefOrEmpty, isObject, jsonToUri, removeUrlParams, windowPop } from 'utils';
import { ShareUrl, WidgetOverrideParams } from 'consts';

const DEFAULT_SHARE_UTM_PARAMS = { utm_source: 'poll_share' };

const buildShareUrl = (url, targetId, shareId, anchorId, utmParams) => {
  if (!url || !targetId || !shareId) {
    return null;
  }
  const paramKey = WidgetOverrideParams.ShareQuestion;
  const refTgtParamKey = 'csw-ref-tgt';
  const utmParamKeys = isObject(utmParams) ? Object.keys(utmParams) : [];
  const cleanUrl = (url.includes(paramKey) || url.includes(refTgtParamKey)) ?
    removeUrlParams([paramKey, refTgtParamKey, ...utmParamKeys], url) : `${url}`;
  const targetIdParam = isObject(utmParams) ? `&${refTgtParamKey}=${targetId}` : '';
  const cleanUtmParams = isNullOrUndefOrEmpty(utmParams) ? '' : `&${jsonToUri(utmParams)}`;
  const urlQuerySep = cleanUrl.includes('?') ? '&' : '?';
  const anchorParam = isNullOrUndefOrEmpty(anchorId) ? '' : cleanUrl.includes('#') ? '' : `#${anchorId}`;
  return `${cleanUrl}${urlQuerySep}${paramKey}=${shareId}${cleanUtmParams}${targetIdParam}${anchorParam}`;
};

const openSocialShare = (shareDetail) => {
  if (!shareDetail) {
    return;
  }
  const { params = {}, isLink = false, width, height, shareUrl } = shareDetail;
  const paramStr = jsonToUri(params);
  const newShareUrl = `${shareUrl}?${paramStr}`;

  if (isLink) {
    window.location.href = newShareUrl;
  } else {
    windowPop(newShareUrl, 'cswSocialShare', width, height);
  }
};

const getShareLink = (pageUrl, targetId, toShare, anchorId, utmParams) => {
  if (!toShare || typeof toShare !== 'object' || !targetId) {
    return null;
  }
  const { sid } = toShare;

  return sid && buildShareUrl(pageUrl, targetId, sid, anchorId, utmParams);
};

const getShareText = (targetId, toShare) => {
  if (!toShare || typeof toShare !== 'object' || !targetId) {
    return null;
  }
  const { text: shareText } = toShare;
  //FIXME: what is the actual text for sharing besides the question text? Is that share text created on the backend?

  return shareText;
};

const shareSocial = (socialPlatform, shareLink, shareTitle) => {
  if (!shareLink || !shareTitle || !socialPlatform) {
    return;
  }

  const socialPlatforms = {
    facebook: { isLink: false, shareUrl: ShareUrl.Facebook, params: { u: shareLink } },
    twitter: { isLink: false, shareUrl: ShareUrl.Twitter, params: { text: shareTitle, url: shareLink } },
    linkedin: { isLink: false, shareUrl: ShareUrl.LinkedIn, params: { url: shareLink, mini: true } }
  };

  return openSocialShare(socialPlatforms[socialPlatform]);
};

export default {
  openSocialShare,
  getShareLink,
  getShareText,
  shareSocial,
  DEFAULT_SHARE_UTM_PARAMS
};
