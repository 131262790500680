/**
 * Returns an array of numbers based on the provided params.
 * The simplest use case is a range starting from 0 with `count` entries:
 *
 * ```
 * range(5); // [0,1,2,3,4]
 * ```
 *
 * You can also control the start and step if needed:
 *
 * ```
 * range(5, { start: 5, step: 2 }); // [5, 7, 9, 11, 13]
 * ```
 *
 * @param count {number}
 * @param start {number}
 * @param step {number}
 * @returns {Array}
 */
const range = (count, { start = 0, step = 1 } = {}) => {
  return Array.from({ length: count }, (_, i) => i + start + ((step - 1) * i));
};

export default range;
