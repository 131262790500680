import { arrayIncludes } from 'utils';

const handleShiftKeyCodeTriggers = (event, keyCodeTriggers = [], callback) => {
  const { keyCode, shiftKey } = event;

  if (shiftKey && keyCode && arrayIncludes(keyCodeTriggers, keyCode) && callback) {
    return callback();
  }

  return null;
};

export default handleShiftKeyCodeTriggers;

