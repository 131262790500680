const DelayTimer = {
  AdminToolRenderDelay: 1000,
  AutoCompleteDelay: 150,
  DeckRefreshHeightMS: 150,
  DefaultAdEngageRefreshMS: 45000,
  FrameLoadIntervalMS: 100,
  FramePortalInitDelayMS: 300,
  FrameResizeDelayMS: 150,
  ResultItemAnimMS: 150,
  SubmitTransitionMS: 333,
  WidgetGameFocusDelay: 300,
  WidgetMenuFocusDelay: 300,
  WidgetInitialResizeDelay: 1000,
  WidgetScrollToDelay: 4000
};

export default DelayTimer;
