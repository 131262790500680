import { isNullOrUndef, isNullOrUndefOrEmpty } from 'utils';

const getGameInfoDateKey = () => {
  const gameInfoDate = new Date();

  return `${gameInfoDate.getFullYear()}-${gameInfoDate.getMonth()}-${gameInfoDate.getDate()}`;
};

const getGameInfoStorageKey = (instanceId) => {
  const gameInfoKey = 'csw-game-info';

  return `${gameInfoKey}-${instanceId}`;
};

const getGameInfoStorage = (instanceId) => {
  const storageId = getGameInfoStorageKey(instanceId);
  const storageVal = !isNullOrUndef(window.localStorage) && window.localStorage.getItem(storageId);
  const storageJson = isNullOrUndefOrEmpty(storageVal) ? null : JSON.parse(storageVal)
  return (storageJson && storageJson.dateKey === getGameInfoDateKey()) ? storageJson : null;
}

const setGameInfoStorage = (gameInfoJson = {}, instanceId, gameEnabled = false) => {
  if (isNullOrUndef(window.localStorage)) {
    return null;
  }

  const storageId = getGameInfoStorageKey(instanceId);

  try {
    return (!gameEnabled || isNullOrUndefOrEmpty(gameInfoJson))
      ? window.localStorage.removeItem(storageId)
      : window.localStorage.setItem(storageId, JSON.stringify(gameInfoJson));
  } catch (_) {
    // ignore
    return null;
  }
};

const deleteGameInfoStorage = (instanceId) => {
  if (isNullOrUndef(window.localStorage)) {
    return null;
  }

  const storageId = getGameInfoStorageKey(instanceId);

  try {
    return window.localStorage.removeItem(storageId);
  } catch (_) {
    // ignore
    return null;
  }
};

const getDailyQuestionAnsweredCount = (instanceId) => {
  const currentGameInfoState = getGameInfoStorage(instanceId);
  let currentCount = 0;

  if (currentGameInfoState && currentGameInfoState.dailyQuestionsAnswered > 0) {
    currentCount = currentGameInfoState.dailyQuestionsAnswered;
  }

  return currentCount;
};

const getDailyQuestionSessionsCompleted = (instanceId) => {
  const currentGameInfoState = getGameInfoStorage(instanceId);
  let currentCount = 0;

  if (currentGameInfoState && currentGameInfoState.dailySessionsCompleted > 0) {
    currentCount = currentGameInfoState.dailySessionsCompleted;
  }

  return currentCount;
};

const getQuestionsToAnswerForNextLevel = (questionIndex = 0,
  sessionLength = 0, completedAllSessions = false, resultActive = false) => {
  return completedAllSessions ? 0 : resultActive ? sessionLength : sessionLength - questionIndex;
};

const increaseDailyQuestionAnsweredCount = (instanceId, gameEnabled = false, sessionCompleted = false) => {
  const gameInfoJson = {
    dateKey: getGameInfoDateKey(),
    dailyQuestionsAnswered: getDailyQuestionAnsweredCount(instanceId) + 1,
    dailySessionsCompleted: getDailyQuestionSessionsCompleted(instanceId) + (sessionCompleted ? 1 : 0)
  };

  return setGameInfoStorage(gameInfoJson, instanceId, gameEnabled);
};

const getGameAwardsState = ({ sessionCount = 1, sessionLength = 1, count }) => {
  const firstGemMultiplier = 1;
  const secondGemMultiplier = 2;
  const thirdGemMultiplier = 3;
  const firstGemQuestionThreshold = (sessionLength * firstGemMultiplier);
  const secondGemQuestionThreshold = (sessionLength * secondGemMultiplier);
  const thirdGemQuestionThreshold = (sessionLength * thirdGemMultiplier);
  const firstGemSessionThreshold = (sessionCount * firstGemMultiplier);
  const secondGemSessionThreshold = (sessionCount * secondGemMultiplier);
  const thirdGemSessionThreshold = (sessionCount * thirdGemMultiplier);
  const firstGemEarned= count >= firstGemSessionThreshold;
  const secondGemEarned= count >= secondGemSessionThreshold;
  const thirdGemEarned= count >= thirdGemSessionThreshold;

  return {
    firstGemQuestionThreshold,
    secondGemQuestionThreshold,
    thirdGemQuestionThreshold,
    firstGemSessionThreshold,
    secondGemSessionThreshold,
    thirdGemSessionThreshold,
    firstGemEarned,
    secondGemEarned,
    thirdGemEarned
  }
};

const getGameState = ({
  instanceId,
  sessionLength = 1,
  gameEnabled = false,
  resultActive = false,
  finishActive = false,
  introActive = false,
  creSlideActive = false,
  questionIndex = 0
}) => {
  const maxAwardedSessions = 3;
  const dailyQuestionAnsweredCount = getDailyQuestionAnsweredCount(instanceId);
  const dailyQuestionSessionsCompleted = getDailyQuestionSessionsCompleted(instanceId);
  const completedAllSessions = (dailyQuestionSessionsCompleted >= maxAwardedSessions);
  const dailyQuestionAwardSessionsCompleted = completedAllSessions
    ? maxAwardedSessions : dailyQuestionSessionsCompleted;
  const dailyQuestionsToAnswerForNextAward = getQuestionsToAnswerForNextLevel(questionIndex,
    sessionLength, completedAllSessions, resultActive);
  const gameCompleted = gameEnabled && completedAllSessions;
  const showGameProgressOnResult = !introActive
    && ((resultActive || finishActive) && gameEnabled && gameCompleted
      && (dailyQuestionSessionsCompleted === maxAwardedSessions));
  const gameHidden = introActive || creSlideActive;
  const showGameProgress = !gameHidden && (showGameProgressOnResult || (gameEnabled && !gameCompleted));
  const showGameMenu = !gameHidden && gameEnabled;

  return {
    completedAllSessions,
    maxAwardedSessions,
    showGameMenu,
    showGameProgress,
    gameCompleted,
    dailyQuestionAnsweredCount,
    dailyQuestionSessionsCompleted,
    dailyQuestionAwardSessionsCompleted,
    dailyQuestionsToAnswerForNextAward,
    awards: getGameAwardsState({
      count: dailyQuestionSessionsCompleted,
      sessionLength
    })
  }
};

export default {
  getGameInfoStorage,
  setGameInfoStorage,
  deleteGameInfoStorage,
  getDailyQuestionAnsweredCount,
  getDailyQuestionSessionsCompleted,
  increaseDailyQuestionAnsweredCount,
  getGameAwardsState,
  getGameState
};
