import { widgetStyling } from 'utils';

const addStyle = (css, id, parentNode) => {
  if (!css || !parentNode || !id || document.getElementById(id)) {
    return;
  }
  const style = document.createElement('style');
  style.setAttribute('id', id);
  style.appendChild(document.createTextNode(widgetStyling.sanitizeStyles(css)));
  parentNode.appendChild(style);
};

export default addStyle;
