const ErrorState = {
  None: 'none',
  Bootstrap: 'bootstrap-fail',
  Target: 'target-fail',
  Finish: 'finish-fail',
  Locale: 'locale-fail',
  Compliance: 'compliance-fail',
  Questions: 'questions-fail',
  Results: 'results-fail',
  Jot: 'jot-fail'
};

export default ErrorState;
