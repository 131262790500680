import { isNullOrUndef, isNullOrUndefOrEmpty } from 'utils';

const DEMOGRAPHICS_LS_KEY = '__iqpbb_d';

/**
 * Gets demographics from local storage
 * @returns {Object} demographics
 */
const getLocalStorageDemographics = () => {
  if (isNullOrUndef(window.localStorage)) {
    return null;
  }
  try {
    const demographicsStr = window.localStorage.getItem(DEMOGRAPHICS_LS_KEY);
    const demographics = isNullOrUndefOrEmpty(demographicsStr) ? null : JSON.parse(demographicsStr)
    return demographics;
  } catch (_) {
    // ignore
    return null;
  }
};

/**
 * Sets demographics in local storage
 * @param {Object} demographics
 * @returns {Boolean} success
 */
const setLocalStorageDemographics = (demographics) => {
  if (!isNullOrUndef(window.localStorage)) {
    try {
      window.localStorage.setItem(DEMOGRAPHICS_LS_KEY, JSON.stringify(demographics));
      return true;
    } catch (_) {
      // ignore
    }
  }
  return false;
};

/**
 * Merges demographics with existing local storage demographics
 * @param {Object} demographics
 * @returns {Boolean} success
 */
const mergeLocalStorageDemographics = (demographics) => {
  if (!demographics || Object.keys(demographics).length <= 0) {
    return false;
  }
  const currentDemographics = getLocalStorageDemographics() || {};
  const mergedDemographics  = Object.assign({}, currentDemographics, demographics);
  return setLocalStorageDemographics(mergedDemographics);
};

export default {
  getLocalStorageDemographics,
  setLocalStorageDemographics,
  mergeLocalStorageDemographics
};
