const createCustomEvent = (eventName, cancelable, detail) => {
  if (!eventName || !eventName) {
    return false;
  }

  detail = detail || {};

  let event;
  try {
    // the new way
    event = new CustomEvent(eventName, {
      bubbles: true,
      cancelable: !!cancelable,
      detail
    });
  } catch (e) {
    // the old way (looking at you IE)
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(eventName, true, cancelable, detail);
  }

  return event;
};

export default createCustomEvent;
