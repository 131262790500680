const WidgetOverrideParams = {
  Draft: 'civicscience-widget-draft',
  Natures: 'civicscience-widget-natures',
  Oip: 'civicscience-widget-oip',
  Question: 'civicscience-widget-question',
  Readonly: 'civicscience-widget-readonly',
  Survey: 'civicscience-widget-survey',
  ShareQuestion: 'csw-sid',
  WidgetAdmin1: 'csw-wa1',
  WidgetAdmin2: 'csw-wa2',
};

export default WidgetOverrideParams;
