import { useLayoutEffect, useState } from 'react';
import { isNullOrUndefOrEmpty } from 'utils';

/**
 *
 * @param activeCondition {boolean}
 * @param completeCondition {boolean}
 * @param animationEventName {string}
 * @param effectDependencies {React.DependencyList}
 * @return {{active: boolean, complete: boolean,
 * animationEndHandler:AnimationEventHandler<HTMLDivElement>|undefined}}
 */
const animationEndEffect = ({
  activeCondition = false,
  completeCondition = false,
  animationEventName = '',
  effectDependencies = []
}) => {
  const [active, setActive] = useState(false);

  useLayoutEffect(() => activeCondition && setActive(true),
    [...effectDependencies]);

  const animationEndHandler = ({ animationName }) =>
    (!isNullOrUndefOrEmpty(animationEventName) && animationName === animationEventName) && setActive(false);

  return {
    active,
    complete: (!active && completeCondition),
    animationEndHandler
  };
};

export default animationEndEffect;
