const WidgetState = {
  Init: 0,              //Initialization
  BootstrapLoading: 1,  //Bootstrap/Config is loading
  BootstrapFailed: 2,   //Bootstrap/Config failed to load
  BootstrapLoaded: 3,   //Bootstrap/Config has loaded
  TargetLoading: 4,     //Target/Config is loading
  TargetFailed: 5,      //Target/Config failed to load
  TargetLoaded: 6,      //Target/Config has loaded
  Hidden: 7,            //Ghost Widget
  TargetAdmin: 8,       //Ghost Widget Target Administration
  BlacklistAdmin: 9,    //DOM Blacklist Administration, widget hidden due to page containing blacklisted HTML elements
  QuestionAdmin: 10,    //Ghost Widget Question Administration
  QuestionsLoading: 11, //Questions are loading
  QuestionsNone: 12,    //No Questions returned
  Questions: 13,        //Questions loaded, show questions
  Finish: 14,           //All Results submitted, now we must call api finish
  Results: 15,          //Questions finished, 'finish' responded, show results
  Summary: 16,          //Conclusion
  ContentRecSlide: 17,  //Content Recommendations
  Error: 18,            //General error state
};

export default WidgetState;
