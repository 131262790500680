class EventHandlerClass {
  constructor() {
    this.listenerMap = {};
  }

  addEventListener(event, listener) {
    const eventType = event.split('.')[0];
    this.listenerMap[event] = listener;
    document.addEventListener(eventType, this.listenerMap[event]);
  }

  removeEventListener(event) {
    const eventType = event.split('.')[0];
    document.removeEventListener(eventType, this.listenerMap[event]);
    delete this.listenerMap[event];
  }
}

export const EventHandler = new EventHandlerClass();
