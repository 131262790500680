const getNodeHeightCeil = (domNode, heightOffset = 0) => {
  if (!domNode || !domNode.getBoundingClientRect) {
    return null;
  }
  const { height } = domNode.getBoundingClientRect();

  return Number.isNaN(height) ? null : `${Math.ceil(height + heightOffset)}px`;
};

export default getNodeHeightCeil;
