import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'micro-i18n';
import { connect } from 'unistore/preact';
import { actions } from 'actions';
import {
  AdPlacement,
  AppMode,
  DelayTimer,
  DISPATCH_EVENTS_PREFIX,
  DispatchEvents,
  ErrorState,
  FileNamePrefix,
  LocaleState,
  WidgetState,
  WrapperMode
} from 'consts';
import {
  benn,
  containsSelector,
  createCustomEvent,
  EventHandler,
  getAppFrameName,
  getTopmostPageUrl,
  isDarkMode,
  isInScrollView,
  isNullOrUndef,
  onFocusRef,
  positionContainer,
  resolveCdn,
  scrollToNode,
  toObject,
  widgetCompliance,
  widgetStyling
} from 'utils';
import { useGAMTargeting } from 'components/ad-engage/hooks';
import { JotType } from 'consts';
import { version as pv } from 'package.json';
import 'components/App.scss';

const Widget = lazy(() => import(/* webpackChunkName: "csw-widget" */ 'components/Widget'));
const AdEngage = lazy(() => import(/* webpackChunkName: "csw-ae" */ 'components/ad-engage/AdEngage'));
const Frame = lazy(() => import(/* webpackChunkName: "csw-frame" */ 'components/frame/Frame'));

const scrollOffsetTop = 200;
const scrollOffsetBottom = 80;
const adPlacementOptions = [AdPlacement.Before, AdPlacement.After, AdPlacement.Left, AdPlacement.Right];

export const App = ({
  appIndex = 0,
  adEngageEnabled,
  bootstrap = {},
  config = {},
  targetId,
  instanceId,
  complianceState,
  errorState,
  localeState,
  widgetState,
  getBootstrap,
  getTarget,
  getLocale,
  getQuestions,
  setDarkMode,
  setWidgetInteractable,
  widgetInteractable,
  setWidgetInScroll,
  widgetInScroll,
  disableResizable,
  theme,
  resetQuestions,
  jot,
  forceDarkMode,
}) => {
  const appViewRef = useRef(null);

  const isAppContainerSmall = (smViewWidth = 600) => (appViewRef && appViewRef.current)
    ? (appViewRef.current?.getBoundingClientRect()?.width ?? (smViewWidth + 1)) <= smViewWidth : false;

  const [appMode, setAppMode] = useState(null);
  const [isPositioned, setIsPositioned] = useState(false);
  const [isScrolledTo, setIsScrolledTo] = useState(false);
  const [isFixed, setIsFixed] = useState(disableResizable);
  const [isContainerSmall, setIsContainerSmall] = useState(isAppContainerSmall());
  const [isAdEmpty, setIsAdEmpty] = useState(false);
  const [hasRequestedQuestions, setHasRequestedQuestions] = useState(false);
  const [headStyle, setHeadStyle] = useState(null);
  const [currFrameHeight, setCurrFrameHeight] = useState(null);
  const { id: bootstrapTargetId, e: editable } = bootstrap;
  const {
    locale, strings, partnerWidgetCss, delayed, delayedOffset, positionable,
    compliance, rootbeer, domBlacklist: blacklistSelector, wrapper, darkMode = false
  } = config;
  const { placement: adPlacement, placementIndex: adPlacementIndex } = rootbeer || AdPlacement.None;
  const cssHref = resolveCdn(`csw.${pv}.css`);
  const cssHrefEditableTools = resolveCdn(`csw-editable-tools.${pv}.css`);
  const cssHrefs = [
    { id: `${FileNamePrefix.CswAppCss}${targetId}`, href: cssHref }
  ];
  editable && cssHrefs.push({ id: `${FileNamePrefix.EditableToolsCss}${targetId}`, href: cssHrefEditableTools });

  const hasBootstrap = widgetState >= WidgetState.BootstrapLoaded && bootstrapTargetId;
  const hasConfig = widgetState >= WidgetState.TargetLoaded && locale;
  const hideOnError = (!editable && errorState !== ErrorState.None) || errorState === ErrorState.Target;
  const canPosition = hasConfig && positionable && positionable['on']
    && !containsSelector(document, blacklistSelector);
  const canFetchLocale = hasConfig && locale && localeState < LocaleState.LocaleLoading;
  //NOTE: isLoaded waits on config, LocaleState.LocaleLoaded AND isPositioned.
  const isLoaded = hasConfig && isPositioned && localeState === LocaleState.LocaleLoaded;
  const complianceReset = compliance && widgetCompliance.needsComplianceReset(compliance, complianceState);
  const isHidden = widgetState === WidgetState.Hidden;
  const isAdminWidget =  widgetState === WidgetState.QuestionAdmin
    || widgetState === WidgetState.TargetAdmin
    || widgetState === WidgetState.BlacklistAdmin;
  const canFetchQuestions = isLoaded && isPositioned && !hasRequestedQuestions && !isHidden && !isAdminWidget;
  let scrollToTimeout = null;
  let delayedLoadTimeout = null;

  useGAMTargeting(config);

  //componentDidMount
  useEffect(() => {
    if (widgetState === WidgetState.Init) {
      // NOTE: Set lang before getting config, because all Components will need language regardless.
      getLocale({ locale: null });
      initializeApp(true);
      !disableResizable && addOnFrameResize();
    }

    //componentWillUnmount
    return () => {
      //NOTE: Remove events from window eventListeners when componentWillUnmount to prevent memory leak.
      scrollToTimeout && clearTimeout(scrollToTimeout);
      delayedLoadTimeout && clearTimeout(scrollToTimeout);
      removeOnFrameResize();
      toggleOnWindow(false);
    };
  }, []);

  //componentDidUpdate specific inputs for config, isLoaded
  useEffect(() => {
    if (widgetStyling.hasStyleOverrides(partnerWidgetCss) && !headStyle) {
      const { override } = partnerWidgetCss;
      setHeadStyle(override);
    }

    !!wrapper && typeof wrapper === 'object'
      && setIsFixed(disableResizable || wrapper.mode === WrapperMode.Fixed);

    if (adEngageEnabled && adPlacementOptions.includes(adPlacement)) {
      //always show Ad Unit
      setAppMode(AppMode.WidgetAd);
    } else {
      setAppMode(AppMode.Widget);
    }
  }, [isLoaded, config]);

  //The following event handler wrappers are used to provide a stable reference to the callback for both
  // addEventListener and removeEventListener
  const onBeforeAskingQuestionsWrapper = () => onBeforeAskingQuestions(adPlacement);
  const onAfterAskingQuestionsWrapper = () => onAfterAskingQuestions(adPlacement);
  const onAfterShowingResultsWrapper = () => onAfterShowingResults(adPlacement);
  const onQuestionAnsweredWrapper = (e) => onQuestionAnswered(e, adPlacement, adPlacementIndex);
  const onResultDepartedWrapper = (e) => onResultDeparted(e, adPlacement, adPlacementIndex);
  const onAdRenderEndedWrapper = (e) => onAdRenderEnded(e);

  const addWidgetEventListeners = () => {
    EventHandler.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.BeforeAskingQuestions}`,
      onBeforeAskingQuestionsWrapper);
    EventHandler.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.AfterAskingQuestions}`,
      onAfterAskingQuestionsWrapper);
    EventHandler.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.AfterShowingResults}`,
      onAfterShowingResultsWrapper);
    EventHandler.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.QuestionAnswered}`,
      onQuestionAnsweredWrapper);
    EventHandler.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.ResultDeparted}`,
      onResultDepartedWrapper);
    EventHandler.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.AdRenderEnded}`,
      onAdRenderEndedWrapper);
  };

  const removeWidgetEventListeners = () => {
    EventHandler.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.BeforeAskingQuestions}`,
      onBeforeAskingQuestionsWrapper);
    EventHandler.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.AfterAskingQuestions}`,
      onAfterAskingQuestionsWrapper);
    EventHandler.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.AfterShowingResults}`,
      onAfterShowingResultsWrapper);
    EventHandler.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.QuestionAnswered}`,
      onQuestionAnsweredWrapper);
    EventHandler.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.ResultDeparted}`,
      onResultDepartedWrapper);
    EventHandler.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.AdRenderEnded}`,
      onAdRenderEndedWrapper);
  };

  //componentDidMount specific inputs for adPlacement
  useEffect(() => {
    !isNullOrUndef(adPlacement) && adPlacement !== AdPlacement.None && addWidgetEventListeners();

    //componentWillUnmount
    return () => {
      removeWidgetEventListeners();
    };
  }, [adPlacement]);

  //when app mode changes, make sure we force a frame resize in case the widget was hidden
  useEffect(() => {
    appMode === AppMode.Widget && setTimeout(() => {
      window.dispatchEvent(createCustomEvent(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.ForceFrameResize}`,
        true));

    }, DelayTimer.FrameLoadIntervalMS);
  }, [appMode]);

  //componentDidMount specific inputs for complianceState
  useEffect(() => {
    complianceReset && initializeApp(false);
  }, [complianceState, complianceReset]);

  useEffect(() => {
    widgetInScroll && !isHidden && !isAdminWidget && jot({ space: 'poll', type: JotType.Viewable });//Only jot viewable when not hidden or ghost widget
  }, [widgetInScroll, isAdminWidget, isHidden]);//NOTE: These useEffect dependencies can only change once in a Widget lifecycle, or else there will be duplicate viewable jots.

  useEffect(() => {
    appViewRef && appViewRef.current && !widgetInScroll && isLoaded && isAppInScroll() && setWidgetInScroll(true);
  }, [appViewRef, isLoaded, widgetInScroll]);

  //componentDidUpdate specific inputs for locale, positioning and fetching questions
  useEffect(() => {
    const isInViewWithOffset = isAppInScrollWithOffset();

    if (isLoaded && !widgetInteractable && appViewRef && appViewRef.current) {
      isInViewWithOffset && setWidgetInteractable(true);
      toggleOnWindow(true);//Always add the onWindow event once the app is loaded
      // We trigger a resize event after a delay so that isAppInScrollWithOffset after page load
      delayedLoadTimeout = setTimeout(() => {
        const resizeEvent = createCustomEvent('resize', true);
        window.dispatchEvent(resizeEvent);
      }, DelayTimer.WidgetInitialResizeDelay);
    }

    if (!isPositioned && hasConfig) {
      canPosition && positionContainer(document.getElementById(instanceId), positionable,
        `${targetId}_${appIndex}`, editable);
      setIsPositioned(true);
    }

    canFetchLocale && getLocale({ locale, stringOverrides: strings });

    if (canFetchQuestions && ((delayed && isInViewWithOffset) || !delayed)) {
      setHasRequestedQuestions(true);
      //getQuestions call is in App instead of Widget due to "delayed render"
      //functionality where we don't make this API call until the app is nearly visible
      getQuestions({ isInitial: true, reset: true });
    }
  }, [appViewRef, isLoaded, widgetInScroll, widgetInteractable, hasConfig, delayed, canFetchQuestions]);

  //componentDidUpdate specific inputs for darkMode, forceDarkMode
  useEffect(() => {
    // IF forceDarkMode data attribute is true, always force dark mode.
    if (forceDarkMode) {
      setDarkMode(true)
    } else {
      // ELSE enable OS dark mode detection if backend darkMode is enabled.
      darkMode ? addOnDarkMode() : removeOnDarkMode();
      darkMode && isDarkMode() && setDarkMode(true);
    }

    //componentWillUnmount
    return () => {
      removeOnDarkMode();
    };
  }, [darkMode, forceDarkMode]);

  //componentDidUpdate specific inputs for scrollTo
  useEffect(() => {
    if (instanceId && isPositioned && !isScrolledTo && appViewRef && appViewRef.current) {
      setIsScrolledTo(true);
      const topmostPageUrl = getTopmostPageUrl();

      if (topmostPageUrl.includes(`#${instanceId}`)) {
        scrollToTimeout = setTimeout(() => scrollToNode(appViewRef.current),
          DelayTimer.WidgetScrollToDelay);
      }
    }
  }, [appViewRef, isScrolledTo, isPositioned, instanceId]);

  // useEffect for isLoaded, resetQuestions
  useEffect(() => {
    isLoaded && resetQuestions && getQuestions({ reset: true });
  }, [isLoaded, resetQuestions]);

  // useEffect for hasBootstrap && hasConfig to getTarget
  useEffect(() => {
    hasBootstrap && !hasConfig && getTarget({ targetId, instanceId });
  }, [hasBootstrap, hasConfig]);

  const initializeApp = (reInitAll = false) => {
    //check if the site has benn-integration
    reInitAll && benn();
    setHasRequestedQuestions(false);
    reInitAll && getBootstrap({ targetId, instanceId });
  };

  const removeOnWindow = (onType) => window.removeEventListener(onType, onWindowChange);

  const addOnWindow = (onType) => window.addEventListener(onType, onWindowChange);

  const toggleOnWindow = (on = false) => {
    if (on) {
      addOnWindow('scroll');
      addOnWindow('resize');
      appViewRef && appViewRef.current && resizeObserver.observe(appViewRef.current);
    } else {
      removeOnWindow('scroll');
      removeOnWindow('resize');
      appViewRef && appViewRef.current && resizeObserver.unobserve(appViewRef.current);
    }
  };

  const isAppInScrollWithOffset = () => isInScrollView({
    domNode: appViewRef.current,
    offsetTop: delayedOffset ? delayedOffset : scrollOffsetTop,
    offsetBottom: scrollOffsetBottom,
    delayed
  });

  const isAppInScroll = () => isInScrollView({
    domNode: appViewRef.current,
    delayed: false,
    offsetBottom: undefined
  });

  const onWindowChange = () => {
    if (appViewRef && appViewRef.current && bootstrapTargetId) {
      !widgetInteractable && isAppInScrollWithOffset() && setWidgetInteractable(true);

      if (!widgetInScroll && isAppInScroll()) {
        setWidgetInScroll(true);

        //NOTE: Remove onWindowChange event from window eventListeners once widgetInScroll is set to true;
        toggleOnWindow(false);
      }
    }
  };

  const resizeObserver = new ResizeObserver(onWindowChange);

  const onBeforeAskingQuestions = (adPlacement) => {
    adPlacement === AdPlacement.Start && setAppMode(AppMode.Ad);
  };

  const onAfterAskingQuestions = (adPlacement) => {
    adPlacement === AdPlacement.Mid && setAppMode(AppMode.Ad);
  };

  const onAfterShowingResults = (adPlacement) => {
    adPlacement === AdPlacement.End && setAppMode(AppMode.Ad);
  };

  const onQuestionAnswered = (e, adPlacement, adPlacementIndex) => {
    adPlacement === AdPlacement.Question && e.detail.index === adPlacementIndex && setAppMode(AppMode.Ad);
  };

  const onAdRenderEnded = (e) => {
    const { isEmpty = false, instanceId: adInstanceId } = toObject(e?.detail);
    adInstanceId === instanceId && setIsAdEmpty(isEmpty ?? false);
  };

  const onResultDeparted = (e, adPlacement, adPlacementIndex) => {
    adPlacement === AdPlacement.Result && e.detail.from === adPlacementIndex && setAppMode(AppMode.Ad);
  };

  const addOnFrameResize = () =>
    window.addEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.FrameResize}`, onFrameResize);

  const removeOnFrameResize = () =>
    window.removeEventListener(`${DISPATCH_EVENTS_PREFIX}${DispatchEvents.FrameResize}`, onFrameResize);

  const onFrameResize = (event) => {
    setIsContainerSmall(isAppContainerSmall());

    !isFixed && event && event.detail && event.detail.frameId === frameNameId
      && setCurrFrameHeight(event.detail.height);
  };

  const addOnDarkMode = () => {
    if (window.matchMedia) {
      const darkMatch = window.matchMedia('(prefers-color-scheme: dark)');
      darkMatch && darkMatch.addEventListener && darkMatch.addEventListener('change', onDarkMode);
    }
  };

  const removeOnDarkMode = () => {
    if (window.matchMedia) {
      const darkMatch = window.matchMedia('(prefers-color-scheme: dark)');
      darkMatch && darkMatch.removeEventListener && darkMatch.removeEventListener('change', onDarkMode);
    }
  };

  const onDarkMode = (event) => {
    darkMode && event && setDarkMode(event.matches);
  };

  // Hiding for certain error states
  if (hideOnError) {
    return null;
  }

  const frameNameId = getAppFrameName(targetId, appIndex);
  //dynamic inline styling for appLoader, AdEngage, and .csw-wrapper
  let frameStyle = { width: '100%', border: 'none', height: currFrameHeight || null };

  const getWrapperHeight = () => {
    const dynamicHeight = (adEngageEnabled || disableResizable) ? '100%'
      : (currFrameHeight ? `${currFrameHeight}px` : '0');

    return isFixed && wrapper ? wrapper.h || '100%' : dynamicHeight;
  };

  const getWrapperWidth = () => isFixed && wrapper && wrapper.w ? wrapper.w : '100%';

  let wrapperStyle = {
    position: 'relative',
    boxSizing: 'border-box',
    display: 'flex',
    width: getWrapperWidth(),
    // NOTE: Calculated wrapper height derived from frameHeight only needed for default wrapperStyle
    // when there is NO ad placement and IS resizable
    height: getWrapperHeight(),
    flexWrap: 'nowrap'
  };

  //FIXME: Responsive would be better than the adaptive checks below.
  // Inline styling doesn't work well with responsive aspects, so we use a tiny bit of adaptive checking for now.
  // It would be better if frameStyle and wrapperStyle objects
  // were moved to injectable css classes or an injected css file instead.
  // If we stick with adaptive, we may need to listen to window for orientationchange and forceUpdate this component
  if (adEngageEnabled) {
    const isFirst = (adPlacement === AdPlacement.Left || adPlacement === AdPlacement.Before);
    const isHorizontal = (adPlacement === AdPlacement.Left || adPlacement === AdPlacement.Right);
    const isVertical = (adPlacement === AdPlacement.After || adPlacement === AdPlacement.Before);

    frameStyle = Object.assign(frameStyle,
      { order: isFirst ? 1 : 0 },
      isHorizontal ? {
        display: 'flex',
        flexGrow: 1,
        minWidth: isContainerSmall ? 0 : '300px',
        maxWidth: '100%'
      } : {});
    if (appMode === AppMode.Ad) {
      //hide widget entirely
      frameStyle = Object.assign(frameStyle, { display: 'none' });
    }
    wrapperStyle = Object.assign(wrapperStyle,
      { justifyContent: isFirst ? 'flex-end' : 'flex-start' },
      isVertical || isContainerSmall ? {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center'
      } : {});
  }

  const onAdNextBtnClick = () => {
    setAppMode(AppMode.Widget);
    onFocusRef(appViewRef);
    if (adPlacement === AdPlacement.End) {
      //restart
      getQuestions({ reset: true });
    }
  };

  return (
    <div className="csw-wrapper"
         style={wrapperStyle}
         ref={appViewRef}
         tabIndex="-1"
         aria-hidden={isHidden}>
      {isLoaded && <Suspense fallback={null}>
        <Frame
          style={frameStyle}
          stylePre="csw-"
          headStyle={headStyle}
          id={frameNameId}
          name={frameNameId}
          instanceId={instanceId}
          className="csw-embed"
          title={i18n.t('FRAME_TITLE')}
          cssHrefs={cssHrefs}
          isResizable={!isFixed}
          frameHeight={getWrapperHeight()}>
          <Suspense fallback={null}>
            <Widget frameName={frameNameId}
              theme={theme}
              isFixed={isFixed} />
          </Suspense>
        </Frame>
      </Suspense>}
      {adEngageEnabled && !isAdEmpty &&
        <Suspense fallback={null}>
          <AdEngage
            instanceId={instanceId}
            bootstrap={bootstrap}
            config={config}
            isWidgetInteractable={widgetInteractable}
            headStyle={headStyle}
            appMode={appMode}
            onBtnClick={onAdNextBtnClick} />

        </Suspense>}
    </div>
  );
};

App.propTypes /* remove-proptypes */ = {
  adEngageEnabled: PropTypes.bool,
  appIndex: PropTypes.number,
  bootstrap: PropTypes.object,
  config: PropTypes.object,
  targetId: PropTypes.string,
  instanceId: PropTypes.string,
  complianceState: PropTypes.string,
  errorState: PropTypes.string,
  localeState: PropTypes.number,
  widgetState: PropTypes.number,
  getBootstrap: PropTypes.func,
  getTarget: PropTypes.func,
  getLocale: PropTypes.func,
  getQuestions: PropTypes.func,
  setDarkMode: PropTypes.func,
  setWidgetInteractable: PropTypes.func,
  widgetInteractable: PropTypes.bool,
  setWidgetInScroll: PropTypes.func,
  widgetInScroll: PropTypes.bool,
  disableResizable: PropTypes.bool,
  theme: PropTypes.string,
  resetQuestions: PropTypes.bool,
  forceDarkMode: PropTypes.bool,
  jot: PropTypes.func
};

export default connect(
`adEngageEnabled,bootstrap,config,complianceState,errorState,localeState,
widgetState,widgetInteractable,widgetInScroll,
resetQuestions`,
actions)(App);
