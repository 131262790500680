import { jot } from 'actions/jot';
import { JotType, WidgetState } from 'consts';

export const onPreviousQuestionFromResult = ({ state }) => {
  if (!state) {
    return;
  }
  const { questionIndex, questionsData, resubmitIds = [], servedIds = [], submitIds = [] } = state;
  const currentQuestion = questionsData?.questions[questionIndex];
  const updatedSubmitIds = submitIds.filter(s => s !== currentQuestion.id);
  const updatedServedIds = servedIds.filter(s => s !== currentQuestion.id);
  const updatedResubmitIds = resubmitIds.includes(currentQuestion.id)
    ? resubmitIds : [...resubmitIds, currentQuestion.id];

  jot({ state, space: 'poll', type: JotType.PreviousQuestion });

  return {
    resubmitIds: updatedResubmitIds,
    submitIds: updatedSubmitIds,
    servedIds: updatedServedIds,
    widgetState: WidgetState.Questions
  };
};
