import { arrayIncludes } from 'utils';

const handleNoShiftKeyCodeTriggers = (event, keyCodeTriggers = [], callback) => {
  const { keyCode, shiftKey } = event;

  if (!shiftKey && keyCode && arrayIncludes(keyCodeTriggers, keyCode) && callback) {
    return callback();
  }

  return null;
};

export default handleNoShiftKeyCodeTriggers;

