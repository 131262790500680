import { QuestionType } from 'consts';
import { isNullOrUndefOrEmpty } from 'utils';

const isTextQuestionValidToSubmit = (question = {}, text, validationRegEx) => {
  let isValid = false;
  const { type } = question;

  if (type === QuestionType.Text) {
    if (isNullOrUndefOrEmpty(validationRegEx)) {
      //No validation
      isValid = true;
    } else {
      const patt = new RegExp(validationRegEx);
      if (patt.test(text)) {
        isValid = true;
      }
    }
  }

  return isValid;
};

export default isTextQuestionValidToSubmit;
