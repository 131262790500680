import { JotType } from 'consts';
import { jot } from 'actions/jot';

export const onHPTriggered = ({ store, state }) => {
  if (!state) {
    return;
  }

  const { config } = state;

  //NOTE updating config in state to have ba=true for future jots in session.
  // TODO: We may want to update jot endpoint for this jot type to set ba=true in the backend.
  const updatedConfig = Object.assign({}, config, { ba: true });
  const updatedState = Object.assign({}, state, { config: updatedConfig });

  store.setState(updatedState);

  jot({ state: updatedState, space: 'poll', type: JotType.BotHoneyPot });

  return updatedState;
};
