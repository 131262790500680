import fetchJsonp from 'fetch-jsonp';
import { WidgetState, Natures, ErrorState, DispatchEvents } from 'consts';
import { envConfig } from 'config';
import { arrayIncludes, handleError, isNullOrUndef, dispatchEvent, isNullOrUndefOrEmpty } from 'utils';

export const getFinishUrl = (questionsData, finishParams = []) => {
  const { finishUrl } = questionsData;
  const { majorVersion } = envConfig;
  const updatedFinishUrl = `${finishUrl}${finishUrl.indexOf('?') === -1 ? '?' : '&'}mv=${majorVersion}`;

  //Remove empty values before joining for finish url params
  const filteredFinishParams = finishParams.filter((fp) => !isNullOrUndefOrEmpty(fp));

  if (filteredFinishParams.length > 0) {
    //if we have finishParams, append to finishUrl
    const finishParamsString = filteredFinishParams.join('&');
    return `${updatedFinishUrl}&${finishParamsString}`;
  }
  //else just use the finishUrl directly
  return updatedFinishUrl;
};

export const getFinish = ({ state }) => {
  const { apiTimeoutMS } = envConfig;
  const { questionsData, finishParams, config, results, instanceId } = state;
  const resultsDisabled = arrayIncludes(config['natures'], Natures.ResultsDisabled);

  const getFinishError = (e) => handleError(ErrorState.Finish, e);

  if (isNullOrUndef(questionsData)) {
    return getFinishError('No Question Data');
  }

  return fetchJsonp(getFinishUrl(questionsData, finishParams), { timeout: apiTimeoutMS })
    .then(res => res.json())
    .then(json => {
      // if we have no results, or if results are disabled, or we have a summary
      // if we have a summary, show summary before showing results (logic must be handled in Summary)

      //FIXME: we are capturing results form state at the beginning of the function
      // so we may be out of sync with general application state??
      // for example if our last question is a quiz question, there's likely no way it has responded
      // by now so we haven't even used the right finishParams in getFinishUrl above
      dispatchEvent(instanceId, this, DispatchEvents.BeforeShowingResults,
        false, { length: results.length });
      const noResults = results.length <= 0;
      const hasSummary = !isNullOrUndef(json.summary);
      const widgetState = (resultsDisabled || noResults || hasSummary) ? WidgetState.Summary : WidgetState.Results;
      return { widgetState, finishData: json };
    }).catch((e) => getFinishError(e));
};
