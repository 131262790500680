export const DISPATCH_EVENTS_PREFIX = 'civicscience:';

const DispatchEvents = {
  AdRenderEnded: 'adRenderEnded',
  AfterAskingQuestions: 'afterAskingQuestions',
  AfterShowingResults: 'afterShowingResults',
  BeforeAskingQuestions: 'beforeAskingQuestions',
  BeforeFetchingQuestions: 'beforeFetchingQuestions',
  BeforeShowingResults: 'beforeShowingResults',
  ForceFrameResize: 'forceFrameResize',
  FrameResize: 'frameResize',
  IDSync: 'idsync',
  QuestionAnswered: 'questionAnswered',
  QuestionAsked: 'questionAsked',
  ResultsReceived: 'resultsReceived',
  ResultArrived: 'resultArrived',
  ResultDeparted: 'resultDeparted'
};

export default DispatchEvents;
