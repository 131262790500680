import { isNullOrUndef } from 'utils';

const getTargetId = (containerDataset = {}, targetOverride) => {
  const { civicscienceWidget, civicsciencePortal } = containerDataset || {};
  let targetId = targetOverride ? targetOverride : civicscienceWidget;
  if (isNullOrUndef(targetId)) {
    //if not found for widget, look up via portal integration code (usersite)
    targetId = civicsciencePortal;
  }

  return targetId;
};

export default getTargetId;
