import { isArray } from 'utils';

export const AD_NOT_FOUND = 'DNE';

/*
  getAd functions must return a valid dictionary of the form
  {
    imageUrl,
    branding,
    title,
    linkUrl,
    id,
    pixels  //optional pixel array
  };
*/

export const getMSNAdImagesArray = (itemWithImages) => {
  let adImages = [];
  if (!itemWithImages) {
    return adImages;
  }

  const { images, title, url, id } = itemWithImages;

  if (isArray(images) && images.length > 0) {
    adImages = images.map((adImage) => {
      return {
               imageUrl: adImage.url,
               branding: adImage.attribution,
               title,
               linkUrl: url,
               id
             };
    });
  }
  return adImages;
};

export const getMSNAdImage = (rootbeer, onFetchComplete) => {
  (rootbeer && rootbeer.url)
    ? fetch(rootbeer.url)
      .then(res => res.json())
      .then(adImageData => {
        let result = AD_NOT_FOUND;
        let adImages = [];

        if (adImageData && isArray(adImageData.value) && adImageData.value.length > 0) {
          const { subCards } = adImageData.value[0] || {};
          if (isArray(subCards) && subCards.length > 0) {
            //get random subcard
            const randomAd = subCards[Math.floor(Math.random() * subCards.length)];
            adImages = getMSNAdImagesArray(randomAd);
            if (adImages.length > 0) {
              result = adImages[0];
            }
          }
        }
        onFetchComplete(result);
      }) :
    onFetchComplete(AD_NOT_FOUND);
};

export const getTaboolaAdImage = (rootbeer, onFetchComplete) => {
  const { url } = rootbeer;
  const taboolaUrl = `${url}${url.indexOf('?') === -1 ? '?' : '&'}source.url=${window.location.href}`;
  fetch(taboolaUrl)
    .then(res => res.json())
    .then(json => {
      let list = json.CivicSciencePoll.list;
      if (list && list.length > 0) {
        const item = list[0];
        const { thumbnail, url: linkUrl, name: title, branding, id, pixels } = item;
        const imageUrl = thumbnail.length > 0 ? thumbnail[0].url : null;
        onFetchComplete({ linkUrl, title, branding, imageUrl, id, pixels });
      }
      else {
        onFetchComplete(AD_NOT_FOUND)
      }
    })
};

export default {
  AD_NOT_FOUND,
  getMSNAdImagesArray,
  getMSNAdImage,
  getTaboolaAdImage
};
