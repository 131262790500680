import fetchJsonp from 'fetch-jsonp';
import { envConfig } from 'config';
import {
  arrayIncludes,
  createTrackingPixels,
  getTopmostPageUrl,
  isNullOrUndef,
  isNullOrUndefOrEmpty,
  jsonToUri,
  dispatchEvent,
  widgetCompliance
} from 'utils';
import {
  DispatchEvents,
  JotType,
  Natures,
  SubmitState,
  WidgetOverrideParams,
  WidgetState
} from 'consts';
import { jot } from 'actions/jot';
import CaptionStatic from 'consts/CaptionStatic';

export const getQuestionsUrl = (state, isInitial = false, questionIndex = 0) => {
  const { protocol, widgetQuestionsEndpoint, majorVersion } = envConfig;
  const {
    userId,
    finishData,
    backupAlias,
    hostname,
    bootstrap,
    config,
    instanceId,
    urlContext,
    excludeParams,
    questionsData
  } = state;
  const { alias, st, session } = questionsData;  //this is null on initial requests
  const { id: targetId, contextualQuestion } = bootstrap;
  const { compliance } = config;
  const { restarts } = finishData;
  const questionsApiUrl = `${protocol}${hostname}${widgetQuestionsEndpoint}`;

  const topmostPageUrl = getTopmostPageUrl(state.articleUrl);
  const complianceOk = widgetCompliance.complianceStorageOk(compliance);
  const backupPPV = complianceOk && compliance && compliance.version;
  const questionParams = {
    'target': targetId,
    'instance': instanceId,
    'context': urlContext,
    'mv': majorVersion,
    '_': Date.now(),
    'idx': questionIndex
  };

  if (backupAlias) {
    questionParams.backupAlias = backupAlias;
  }
  if (userId) {
    questionParams.externalUserId = userId;
  }

  if (backupPPV) {
    questionParams.backupPPV = backupPPV;
  }

  if (alias) {
    //pass alias if it exists
    //this helps cover for our pre-compliance->compliance users
    //to maintain a consistent alias pre/post cookie
    questionParams.alias = alias;
  }

  if (st) {
    //pass session template if it exists
    //this helps us preserve the session template across questions
    //in the 1q at a time context.
    questionParams.template = st;
  }

  //if finish data provided a restarts value to use, use it
  //so that the api knows how many times the user has 'restarted' an entire new session
  if (restarts) {
    questionParams.restarts = restarts;
  }

  //pass session uuid to keep the session id consistent between requests
  if (session) {
    questionParams.session = session;
  }

  // isInitial is needed because these extra url-based params can only be added to the first getQuestion call,
  // and then ignore after.
  if (isInitial) {
    //add url-based question to fetch if it exists
    const qm = topmostPageUrl.match(new RegExp(`[?&]${WidgetOverrideParams.Question}=([^&#]+)`));
    if (!isNullOrUndef(qm)) {
      questionParams.question = [decodeURIComponent(qm[1])].join(',');
    } else if (!isNullOrUndefOrEmpty(contextualQuestion)) {
      questionParams.question = contextualQuestion;
    }
    const sm = topmostPageUrl.match(new RegExp(`[?&]${WidgetOverrideParams.Survey}=([^&#]+)`));
    if (!isNullOrUndef(sm)) {
      questionParams.survey = decodeURIComponent(sm[1]);
    }
    //add url-based share id to fetch if it exists
    const encSid = topmostPageUrl.match(new RegExp(`[?&]${WidgetOverrideParams.ShareQuestion}=([^&#]+)`));
    if (!isNullOrUndef(encSid)) {
      questionParams.sid = decodeURIComponent(encSid[1]);
    }
  }

  //TODO migrate questionParams.locale=options.locale if needed
  //TODO migrate questionParams.template=options.template if needed
  //TODO migrate restartDataCache

  const questionParamsString = jsonToUri(questionParams);
  if (excludeParams && excludeParams.length > 0) {
    const excludeParamsString = excludeParams.join('&');
    return `${questionsApiUrl}?${questionParamsString}&${excludeParamsString}`;
  }
  else {
    return `${questionsApiUrl}?${questionParamsString}`;
  }
};

/**
 * Merge `updatedQuestionsData.questions` into existing `questionsData.questions`,
 * and return `updatedQuestionsData` with those questions populated.
 *
 * @param {Object} questionsData
 * @param {Object} updatedQuestionsData
 * @returns `updatedQuestionsData` with those questions populated
 */
export const mergeQuestions = (questionsData, updatedQuestionsData) => {
  const { questions = [] } = questionsData || {};
  const mergedQuestionsArray = questions.concat(updatedQuestionsData.questions);
  return Object.assign({}, updatedQuestionsData, { questions: mergedQuestionsArray });
};

/**
 * Call questions endpoint to return questions data, one question at a time per `questionIndex`.
 */
export const getQuestions = ({ store, state, isInitial = false, reset = false, questionIndex = 0 }) => {
  const { apiTimeoutMS } = envConfig;
  const {
    finishData, config, instanceId, complianceState, questionsData, trackingPixelsInserted
  } = state;
  const { compliance, natures: configNatures } = config;
  const needsComplianceResponse = widgetCompliance.needsComplianceResponse(compliance, complianceState);
  const resultsDisabled = arrayIncludes(configNatures, Natures.ResultsDisabled);

  //dispatch on initial fetch of questions
  reset && dispatchEvent(instanceId, this, DispatchEvents.BeforeFetchingQuestions, false);

  // Reset various question/result/finish state
  const storeInit = {
    results: [],
    finishParams: [],
    resultIndex: 0,
    resultResponseCount: 0,
    questionsData: {},
    questionIndex: 0,
    widgetState: WidgetState.QuestionsLoading,
    submitState: SubmitState.NotReady,
    resetQuestions: false,
    servedIds: [],
    submitIds: [],
    staticCaptions: Object.assign([], CaptionStatic),
  };
  reset && store.setState(storeInit);

  const questionUrl = getQuestionsUrl(store.getState(), isInitial, questionIndex);

  return fetchJsonp(questionUrl, { timeout: apiTimeoutMS })
    .then(res => res.json())
    .then(json => {
      const { excludeParams } = store.getState();
      const { st, stg, tracking, conclusion, questions, session, locale, alias, excludeData } = json;
      //did we immediately get results or summary instead of questions? [special case]
      const hasConclusion = !isNullOrUndef(conclusion);

      // track session template and session template group on initial calls for
      // the first question of a session
      // NOTE this may be a random alias for a non-complied user
      reset && jot({
        state,
        space: 'poll',
        type: JotType.Templates,
        data: { st, stg, session, locale, alias }
      });

      // third-party cookie sync and tracking pixels for the initial impression
      if (!needsComplianceResponse &&
        !trackingPixelsInserted &&
        !finishData['restartUrl'] &&
        tracking &&
        tracking['pixels']) {
        createTrackingPixels(tracking['pixels']);
        store.setState({ trackingPixelsInserted: true })
      }

      if (excludeData) {
        //append excluded question id params
        //the purpose of this is to append these parameters so we have a buffer of time
        //where we do not re-ask questions to users
        excludeParams.push(excludeData);
        //only keep up to 30 in queue
        excludeParams.length > 30 && excludeParams.shift();
      }

      if (hasConclusion) {
        //SPECIAL CASE where we asked for questions but immediately got results and/or summary back
        //FIXME: this code is also in getFinish, we should figure out how to centralize
        //Note: conclusion below contains the necessary restartUrl, portalUrl (if applicable) and results OR summary
        let { results = [] } = conclusion;
        const noResults = results.length <= 0;
        const hasSummary = !isNullOrUndef(json.summary);

        const widgetState = (resultsDisabled || noResults || hasSummary) ? WidgetState.Summary : WidgetState.Results;
        //flatten array
        return { widgetState, finishData: conclusion, results, questionsData: Object.assign({}, json) };
      } else if (questions.length <= 0) {
        //no questions
        return { widgetState: WidgetState.QuestionsNone, questionsData: Object.assign({}, json) };
      } else {
        // We have questions
        if (reset) {
          // In reset mode, use response json and prepare to ask the first quesiton.
          return {
            widgetState: WidgetState.Questions,
            questionsData: Object.assign({}, json),
            questionIndex: 0,
            excludeParams
          };
        }
        else {
          // Otherwise, merge the new questions response into the existing data to grow
          // the list of questions inside questionsData, given we are fetching one question
          // at a time.
          const mergedQuestionsData = mergeQuestions(questionsData, json);
          return {
            widgetState: WidgetState.Questions,
            questionsData: mergedQuestionsData,
            excludeParams
          };
        }
      }
    });
};
