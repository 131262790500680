const JotType = {
  BotHoneyPot: 'bot-hp',
  Clk: 'clk',
  Compliance: 'compliance',
  ComplianceShown: 'compliance-shown',
  ComplianceShownFirst: 'compliance-shown-first',
  ContentRecommendationClick: 'content-rec-click',
  ContentRecommendationShown: 'content-rec-shown',
  Created: 'created',
  Resolved: 'resolved',
  EndUsersiteLink: 'end-usersite-link',
  EndRestart: 'end-restart',
  Finished: 'finished',
  Imp: 'imp',
  LastResult: 'last-result',
  MenuHistory: 'menu-history',
  MenuOo: 'menu-oo',
  MenuOoN: 'menu-oo-n',
  MenuOoY: 'menu-oo-y',
  MenuOpen: 'menu-open',
  NextResult: 'next-result',
  PreviousQuestion: 'previous-question',
  PreviousResult: 'previous-result',
  ReportClick: 'report-click',
  ReportSubmit: 'report-submit',
  ReportCancel: 'report-cancel',
  Served: 'served',
  Share: 'share',
  ShareClick: 'share-click',
  Submit: 'submit',
  Templates: 'templates',
  Viewable: 'viewable',
};

export default JotType;
