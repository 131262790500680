import { dispatchEvent, isArray } from 'utils';
import { DispatchEvents, JotType } from 'consts';
import { jot } from 'actions/jot';

export const onPreviousResult = ({ state }) => {
  if (!state) {
    return;
  }

  const { instanceId, resultIndex, results } = state;
  const previousIndex = resultIndex - 1;

  const resultDetail = { from: resultIndex, to: previousIndex, length: isArray(results) ? results.length : 0 };

  //NOTE: yes, the equal dispatches with different names look bizarre. just matching v4 events.
  dispatchEvent(instanceId, this, DispatchEvents.ResultDeparted, false, resultDetail);

  //TODO: to "properly" match v4, resultArrived should not fire until after a re-render of this
  // stateful widget with a different result index
  dispatchEvent(instanceId, this, DispatchEvents.ResultArrived, false, resultDetail);

  jot({ state, space: 'poll', type: JotType.PreviousResult });

  return { resultIndex: previousIndex };
};
